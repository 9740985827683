import { types } from '../constants/ActionTypes'

const initialState = {
  nyanko: {
    puzzles: [],
    highScore: {
      all: -1,
      daily: -1,
    },
    ranking: {
      all: [],
      daily: [],
    }
  }
}

const puzzle = (state = initialState, action) => {
  switch (action.type) {
    case types.user.RECEIVE_PROFILE:
      if (action.response.success !== true) {
        return state
      }
      return {
        ...state,
        nyanko: {
          ...state.nyanko,
          highScore: {
            all: action.response.user.puzzle.nyanko.highScore.all,
            daily: action.response.user.puzzle.nyanko.highScore.daily,
          }
        }
      }
    case types.puzzle.nyanko.RECEIVE_PUZZLES:
      if (state.nyanko.puzzles.some(puzzle => puzzle.level === action.level && puzzle.color === action.color)) {
        return { ...state }
      }
      return {
        ...state,
        nyanko: {
          ...state.nyanko,
          puzzles: state.nyanko.puzzles.concat(
            action.puzzles.map((puzzle, index) => {
              return {
                ...puzzle,
                moves: puzzle.moves.split(',').map((move, index) => {
                  return {
                    x: parseInt(move[0], 16) - 1,
                    y: parseInt(move[1], 16) - 1,
                    color: index % 2 === 0 ? 'blackcat' : 'whitecat'
                  }
                }),
                answer: {
                  x: parseInt(puzzle.answer[0], 16) - 1,
                  y: parseInt(puzzle.answer[1], 16) - 1,
                },
                index: state.nyanko.puzzles.length + index,
                level: action.level
              }
            })  
          )
        }
      }
    case types.puzzle.nyanko.UPDATE_HIGH_SCORE:
      return {
        ...state,
        nyanko: {
          ...state.nyanko,
          highScore: {
            all: state.nyanko.highScore.all < action.score ? action.score : state.nyanko.highScore.all,
            daily: state.nyanko.highScore.daily < action.score ? action.score : state.nyanko.highScore.daily,
          }
        }
      }
    case types.puzzle.nyanko.RECEIVE_RANKING:
      if (action.response.success !== true) {
        return state
      }
      return {
        ...state,
        nyanko: {
          ...state.nyanko,
          ranking: {
            all: action.response.all,
            daily: action.response.daily,
          }
        }
      }
    default:
      return state
  }
}

export default puzzle