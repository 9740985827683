import { connect } from 'react-redux'
import { TopicsPage } from '../../components'
import * as actions from '../../actions'

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  const { posts } = state.wordpress
  var post = posts.find(a => a.slug === ownProps.match.params.slug)
  return ({
    width,
    height,
    post
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    mount: () => {
      dispatch(actions.wordpress.fetchCustomPosts(
        ownProps.match.params.type,
        `?slug=${ownProps.match.params.slug}&_embed`
      ))
    }
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicsPage)
