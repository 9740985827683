import { connect } from 'react-redux'
import { Board } from '../../components'

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  return ({
    width,
    height,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Board)
