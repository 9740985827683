import React from 'react'
import { Route, Switch } from 'react-router'
import { RulePage } from '../../containers'
import { LANGUAGE_SHORT_CODE } from '../../constants';

class TopicsRouting extends React.Component {
  render() {
    var regstr = Object.keys(LANGUAGE_SHORT_CODE).map(key => LANGUAGE_SHORT_CODE[key]).join('|')
    return (
      <Switch>
        <Route exact path='/rule' component={RulePage} />
        <Route exact path={`/:language(${regstr})/rule`} component={RulePage}/>
      </Switch>
    )
  }
}

export default (TopicsRouting)
