import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, ButtonBase } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Word } from '../../containers';
import { WID } from '../../constants/Language';
import { makePath } from '../../lib';

const styles = theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: theme.spacing.unit,
    width: '100%',
    height: theme.spacing.unit * 6,
    margin: `${theme.spacing.unit * 4}px auto`,
    boxShadow: `0px 5px 0px 0px #00000022`,
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow: 'none',
      transform: 'translate(0px,1px)',
    },
  },
  buttonLabel: {
    color: theme.palette.primary.main,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.3em',
  }
})

class PlayPage extends React.Component {
  render() {
    const { classes, language } = this.props

    return (
      <React.Fragment>
        <ButtonBase component={Link} to={makePath('/play/vs', language)} className={classes.button}>
          <Typography className={classes.buttonLabel}><Word wid={WID.PLAY_PAGE.SELECT_MODE_PAGE.VS_CPU}/></Typography>
        </ButtonBase>
        <ButtonBase component={Link} to={makePath('/play/puzzle', language)} className={classes.button}>
          <Typography className={classes.buttonLabel}><Word wid={WID.PLAY_PAGE.SELECT_MODE_PAGE.PUZZLE}/></Typography>
        </ButtonBase>
        <ButtonBase component={Link} to={makePath('/play/freeboard', language)} className={classes.button}>
          <Typography className={classes.buttonLabel}><Word wid={WID.PLAY_PAGE.SELECT_MODE_PAGE.FREE_BOARD}/></Typography>
        </ButtonBase>
      </React.Fragment>
		)
  }
}

export default withStyles(styles, { withTheme: true })(PlayPage)
