import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Fade, Typography, Divider, Grid } from "@material-ui/core"
import moment from 'moment'
import { PICTURES } from '../../constants'

const styles = theme => ({
  titleBox: {

  },
  tagAndDate: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 2,
  },
  date: {
    color: theme.palette.grey[500],
    fontSize: '0.7rem',
  },
  title: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  thumbnail: {
    width: '100%',
    objectFit: 'contain',
  },
  image: {
    width: 40,
    height: 40,
    objectFit: 'contain',
    border: `1px solid ${theme.palette.grey[100]}`,
    margin: theme.spacing.unit / 2,
  },
  detailBox: {
  },
  name: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '2em',
  },
  price: {
    lineHeight: '2em',
  },
  baseImage: {
    padding: theme.spacing.unit * 2,
    height: theme.spacing.unit * 6,
  },
  amazonImage: {
    padding: theme.spacing.unit * 2,
    height: theme.spacing.unit * 6,
  },
})

class GoodsPage extends React.Component {
  _isMounted = false

  state = {
    selectedImage: 0,
    media: [],
    loaded: [], 
  }

  componentDidMount() {
    this._isMounted = true
    this.props.mount(response => {
      if (this._isMounted === true) {
        this.setState({ media: response })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleClickImage = (index) => (event) => {
    this.setState({ selectedImage: index })
  }

  handleLoadedImage = (index) => () => {
    if (this._isMounted === true) {
      this.setState({ loaded: this.state.loaded.concat(index) })
    }
  }
  
  render() {
    const { classes, post } = this.props
    const { media, selectedImage, loaded } = this.state
    var images, metaPropName, visibleCardMedia, foundMedia

    visibleCardMedia = Boolean(
      post !== undefined &&
      post._embedded !== undefined &&
      post._embedded['wp:featuredmedia'] !== undefined &&
      post._embedded['wp:featuredmedia'].length > 0 &&
      post._embedded['wp:featuredmedia'][0].source_url !== undefined
    )

    images = []
    metaPropName = [ 'image_1', 'image_2', 'image_3', 'image_4' ]
    if (post !== undefined) {
      if (visibleCardMedia) {
        images.push({
          id: `${post._embedded['wp:featuredmedia'][0].id}`,
          sourceUrl: post._embedded['wp:featuredmedia'][0].source_url,
        })
      }
      metaPropName.forEach(name => {
        if (post.meta[name][0] !== '') {
          foundMedia = media.find(md => md.id === parseInt(post.meta[name][0]))
          images.push({
            id: post.meta[name][0],
            sourceUrl: foundMedia !== undefined ? foundMedia.source_url : undefined
          })
        }
      })
    }

    return post !== undefined ? (
      <div>
        <Grid container spacing={16}>
          <Grid item sm={6} xs={12}>
            <Fade in={visibleCardMedia}>
              {visibleCardMedia && images[selectedImage].sourceUrl !== undefined ? (
                <img
                  src={images[selectedImage].sourceUrl}
                  alt='img'
                  className={classes.thumbnail}
                />
              ) : (
                <div></div>
              )}
            </Fade>
            {images.map((image, index) => {
              return image.sourceUrl !== undefined ? (
                <Fade in={loaded.some(ld => ld === index)} key={index}>
                  <img
                    src={image.sourceUrl}
                    alt='img'
                    className={classes.image}
                    onClick={this.handleClickImage(index)}
                    onLoad={this.handleLoadedImage(index)}
                  />
                </Fade>
              ) : (<div key={index}></div>)
            })}
          </Grid>
          <Grid item sm={6} xs={12}>
            <div className={classes.titleBox}>
              <div className={classes.tagAndDate}>
                <div className={classes.date}>{moment(post.date).format('YYYY.MM.DD')}</div>
              </div>
              <Typography variant='h6' className={classes.title}>
                <span dangerouslySetInnerHTML={{__html: post.title.rendered }}></span>
              </Typography>
            </div>
            <Divider className={classes.divider}/>
            <div className={classes.detailBox}>
              <Typography className={classes.name[0]}>商品名 : {post.meta.name[0]}</Typography>
              <Typography className={classes.price[0]}>価格 : {post.meta.price[0]} 円</Typography>
              {post.meta.url_base[0] !== '' && (
                <a href={post.meta.url_base[0]} target='_blank' rel="noopener noreferrer">
                  <img
                    src={PICTURES.BASE}
                    alt='img'
                    className={classes.baseImage}
                  />
                </a>
              )}
              {post.meta.url_amazon[0] !== '' && (
                <a href={post.meta.url_amazon[0]} target='_blank' rel="noopener noreferrer">
                  <img
                    src={PICTURES.AMAZON}
                    alt='img'
                    className={classes.amazonImage}
                  />
                </a>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    ) : (<div></div>)
  }
}

export default withStyles(styles, { withTheme: true })(GoodsPage)
