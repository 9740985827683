import { types } from '../constants/ActionTypes'

const initialState = {
  width: undefined,
  height: undefined,
}

const windowSize = (state = initialState, action) => {
  switch (action.type) {
    case types.router.LOCATION_CHANGE:
      if (window) {
        window.scrollTo(0, 0)
      }
      return state
    case types.window.RESIZE:
      return { ...state, width: action.width, height: action.height }
    default:
      return state
  }
}

export default windowSize