import { LANGUAGE_SHORT_CODE } from "../constants";

export const makePath = (url, language) => {
  var params, regstr, reObj
  params = url.split('/')
  if (params.length < 2) {
    return url
  }
  
  regstr = Object.keys(LANGUAGE_SHORT_CODE).map(key => '^' + LANGUAGE_SHORT_CODE[key] + '$').join('|')
  reObj = new RegExp(regstr, 'g')
  if (Boolean(params[1].match(reObj))) {
    params[1] = language
  }
  else {
    params.splice( 1, 0, language )
  }
  if (language === LANGUAGE_SHORT_CODE.JA) {
    params.splice(1, 1)
  }
  return params.join('/')
}

export const getLanguageShortCode = (url) => {
  var params, regstr, reObj
  params = url.split('/')
  if (params.length < 2) {
    return LANGUAGE_SHORT_CODE.JA
  }
  
  regstr = Object.keys(LANGUAGE_SHORT_CODE).map(key => '^' + LANGUAGE_SHORT_CODE[key] + '$').join('|')
  reObj = new RegExp(regstr, 'g')
  
  return Boolean(params[1].match(reObj)) ? params[1] : LANGUAGE_SHORT_CODE.JA
}

export const getPageCategory = (pathname, language) => {
  var path = pathname ? pathname.split('/') : []
  if (path.length > 1 && language === LANGUAGE_SHORT_CODE.JA) {
    return path[1]
  }
  else if (path.length > 2 && language !== LANGUAGE_SHORT_CODE.JA) {
    return path[2]
  }
  else {
    return ''
  }
}