import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { PlayNavigation } from './Common'
import { Typography, ButtonBase } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { WID } from '../../constants/Language';
import { Word } from '../../containers';
import { makePath } from '../../lib';

const styles = theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    height: theme.spacing.unit * 6,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 5px 0px 0px #00000022`,
    userSelect: 'none',
    margin: '0 auto',
    color: theme.palette.primary.main,
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow: 'none',
      transform: 'translate(0px,1px)',
    },
  },
  buttonLabel: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1em',
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.common.white,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.3em',
    textAlign: 'center',
  },
  section: {
    marginBottom: theme.spacing.unit * 4,
    marginLeft: theme.spacing.unit * 2,
  }
})

class PuzzlePage extends React.Component {

  render() {
    const { classes, language } = this.props

    return (
      <React.Fragment>
        <PlayNavigation title={<Word wid={WID.PLAY_PAGE.PUZZLE_PAGE.TITLE}/>} back={makePath('/play', language)}/>
        <div className={classes.section}>
          <Typography className={classes.subtitle}><Word wid={WID.PLAY_PAGE.PUZZLE_PAGE.SELECT_PUZZLE}/></Typography>
          <ButtonBase className={classes.button} component={Link} to={makePath(`/play/puzzle/nyanko`, language)}>
            <Typography className={classes.buttonLabel} color='inherit'><Word wid={WID.PLAY_PAGE.PUZZLE_PAGE.NYANKO_PUZZLE}/></Typography>
          </ButtonBase>
        </div>
      </React.Fragment>
		)
  }
}

export default withStyles(styles, { withTheme: true })(PuzzlePage)
