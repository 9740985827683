import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, ButtonBase } from '@material-ui/core'
import { KeyboardArrowLeftRounded as LeftIcon, KeyboardArrowRightRounded as RightIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { Word } from '../../containers';

const stylesForPlayTitle = theme => ({
	root: {
		width: '100%',
		maxWidth: 900,
		display: 'flex',
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
		margin: `${theme.spacing.unit * 6}px auto ${theme.spacing.unit * 9}px auto`,
	},
	line: {
		width: '100%',
		height: theme.spacing.unit / 2,
		maxWidth: 884,
		backgroundColor: theme.palette.common.white,
		borderRadius: theme.spacing.unit / 4,
		margin: `0 ${theme.spacing.unit}px`,
	},
	title: {
		position: 'absolute',
		fontSize: '2em',
		marign: '0 auto',
		padding: theme.spacing.unit * 3,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
		fontWeight: 400,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},  
})

export const PlayTitle = withStyles(stylesForPlayTitle, { withTheme: true })((props) => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <div className={classes.line}></div>
      <Typography className={classes.title}>あそぶ</Typography>
    </div>
  )
})


const stylesForPlayNavigation = theme => ({
	titleBox: {
		width: '100%',
		display: 'flex',
		position: 'relative',
		justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing.unit * 6,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing.unit,
    color: theme.palette.primary.main,
    margin: `${theme.spacing.unit * 4}px auto`,
  },
  title: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.3em',
  },
  icon: {
    fontSize: theme.spacing.unit * 4,
  },
  left: {
    position: 'absolute',
    left: theme.spacing.unit,
  },
  right: {
    position: 'absolute',
    right: theme.spacing.unit,
  }
})

export const PlayNavigation = withStyles(stylesForPlayNavigation, { withTheme: true })((props) => {
  const { classes, title, next, back, onClick } = props
  return (
    <div className={classes.titleBox}>
      <Typography color='inherit' className={classes.title}>{title}</Typography>
      {back !== undefined ? (
        <ButtonBase component={Link} to={back} className={classes.left}>
          <LeftIcon color='inherit' className={classes.icon}/>
        </ButtonBase>
      ) : onClick !== undefined ? (
        <ButtonBase className={classes.left} onClick={onClick}>
          <LeftIcon color='inherit' className={classes.icon}/>
        </ButtonBase>
      ) : undefined}
      {next !== undefined && (
        <ButtonBase component={Link} to={next} className={classes.right}>
          <RightIcon color='inherit' className={classes.icon}/>
        </ButtonBase>
      )}
    </div>
  )
})

const stylesForRadioButtonGroup = theme => ({
  radioButton: {
    display: 'flex',
    marginBottom: theme.spacing.unit,
    marginLeft: -theme.spacing.unit * 7,
  },
  center: {
    justifyContent: 'center',
  },
  checkIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing.unit * 2,
    height: theme.spacing.unit *2,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing.unit * 2,
  },
  checked: {
    width: theme.spacing.unit,
    height: theme.spacing.unit,
    borderRadius: theme.spacing.unit / 2,
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 2,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 5px 0px 0px #00000022`,
    userSelect: 'none',
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow: 'none',
      transform: 'translate(0px,1px)',
    },
  },
  selectedButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 2,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.white,
    userSelect: 'none',
    transition: 'all 0.5s',
    transform: 'translate(0px,1px)',
  },
  label: {
    color: theme.palette.primary.main,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1em',
  }
})

export const RadioButtonGroup = withStyles(stylesForRadioButtonGroup, { withTheme: true })((props) => {
  const { classes, labelProps, selected, onClick, center } = props

  return (
    <div>
      {labelProps && labelProps.map(label => 
        <div className={`${classes.radioButton} ${center === true ? classes.center : ''}`} key={label.value}>
          <div
            className={classes.checkIcon}
            onClick={(event) => onClick(label.value)}
          >
            {selected === label.value && (
              <div className={classes.checked}></div>
            )}
          </div>
          <div
            className={selected === label.value ? classes.selectedButton : classes.button}
            onClick={(event) => onClick(label.value)}
          >
            <Typography className={classes.label}><Word wid={label.wid}/></Typography>
          </div>
        </div>
      )}
    </div>
  )
})

const stylesForCat = theme => ({})

export const Cat = withStyles(stylesForCat, { withTheme: true })((props) => {
  const { theme, type } = props
  const styles = {
    component1: {
      fill: type === 'blackcat' ? theme.palette.common.black : theme.palette.common.white
    },
    component2: {
      fill: type === 'blackcat' ? theme.palette.common.white : theme.palette.common.black
    }
  }
  return (
    <g id={type}>
      <path style={styles.component1} d='M91.47,30.77c1.74-4,2.24-9.68,2.24-13.59,0-6.71-.6-15.67-5.06-14.82C81.47,3.73,74,6.62,69,10.84A48.11,48.11,0,0,0,30.72,11c-5-4.29-12.57-7.22-19.84-8.61-4.46-.85-5.05,8.11-5.05,14.82,0,4.06.53,10,2.44,14A47.71,47.71,0,0,0,2,54.94C2,81.45,23.49,96,50,96s48-14.59,48-41.1A47.75,47.75,0,0,0,91.47,30.77Z'/>
      <circle style={styles.component2} cx='29.13' cy='51.94' r='3.6' />
      <circle style={styles.component2} cx='70.41' cy='51.94' r='3.6' />
      <path style={styles.component2} d='M57.46,70.75c-1.63,1.25-3,1-4.34-.48a10,10,0,0,1-1.77-2.68l0,0V64.39A3.23,3.23,0,0,0,53,61.58c0-1.79-1.38-2.34-3.18-2.34s-3.32.55-3.32,2.34a3.24,3.24,0,0,0,1.74,2.87V67.9a2.11,2.11,0,0,0,0,.26,10.17,10.17,0,0,1-1.49,2.11c-1.37,1.45-2.71,1.73-4.34.48s-3.15,1.5-1.58,2.7c3.39,2.58,6.93.75,9.12-2.16C52.11,74.2,55.65,76,59,73.45a1.63,1.63,0,0,0,.56-2.14A1.58,1.58,0,0,0,57.46,70.75Z'/>
      <path style={styles.component2} d='M89.83,68.89l-12.2-1c-2-.17-2,3,0,3.12l12.2,1C91.83,72.17,91.82,69.05,89.83,68.89Z'/>
      <path style={styles.component2} d='M77.64,66.17l12.17-1.33c2-.22,2-3.34,0-3.12L77.64,63.05C75.67,63.26,75.65,66.38,77.64,66.17Z'/>
      <path style={styles.component2} d='M21.91,67.88l-12.2,1c-2,.16-2,3.28,0,3.12l12.2-1C23.89,70.83,23.91,67.71,21.91,67.88Z'/>
      <path style={styles.component2} d='M9.72,64.84l12.17,1.33c2,.21,2-2.91,0-3.12L9.72,61.72C7.73,61.5,7.75,64.62,9.72,64.84Z'/>
    </g>
  )
})
