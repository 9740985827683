import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { orange } from '@material-ui/core/colors'
import { POST_TYPES } from '../../constants'
import { WID } from '../../constants/Language';
import { Word } from '../../containers';

const styles = theme => ({
  chip: {
    marginRight: theme.spacing.unit,
    fontSize: '0.5rem',
    padding: `${theme.spacing.unit / 4}px ${theme.spacing.unit}px`,
    color: theme.palette.common.white,
    borderRadius: 100,
  },
})

const PostTypeTag = props => {
  const { classes, theme, type } = props  
  const postTypeItems = [
    { type: POST_TYPES.NEWS,   wid: WID.TOPICS_PAGE.TAGS.NEWS, backgroundColor: theme.palette.primary.main },
    { type: POST_TYPES.EVENTS, wid: WID.TOPICS_PAGE.TAGS.EVENT, backgroundColor: orange[500] },
  ]

	var item, wid, styles
  item = postTypeItems.find(it => it.type === type)
  wid = item !== undefined ? item.wid : ''
  styles = {
    chip: {
      backgroundColor: item.backgroundColor,
    }
  }
	return (
		<div className={classes.chip} style={styles.chip}><Word wid={wid}/></div>
	)
}

export default withStyles(styles, { withTheme: true })(PostTypeTag)
