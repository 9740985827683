import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  cls1: {
    fill: 'none',
    stroke: '#00000022',
    strokeMiterlimit: 10,
    strokeWidth: 8,
  },
  cls2: {
    fill: 'none',
    stroke: '#ffffff',
    strokeMiterlimit: 10,
    strokeWidth: 8,
  },
  cls3: {
    isolation: 'isolate',
  },
  cls4: {
    mixBlendMode: 'multiply',
  },
  cls5: {
    clipPath: 'url(#clip-path)',
  },
  cls6: {
    fill: 'none',
    stroke: '#00000022',
    strokeMiterlimit: 10,
    strokeLinecap: 'round',
    strokeWidth: 2,
  },
  cls7: {
    clipPath: 'url(#clip-path-2)',
  },
  cls8: {
    fill: 'none',
    stroke: '#ffffff',
    strokeMiterlimit: 10,
    strokeLinecap: 'round',
    strokeWidth: 2,
  },
  cls9: {
    fill: '#040000',
  },
  cls10: {
    fill: '#fff',
  },
  cls11: {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
  },
  cls12: {
    fill: '#fff',
  },
  cls13: {
    fill: '#e4007f',
  },
  catAnimation: {
    transform: 'scale(0, 0)',
    animation: 'popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
  },
  black1: { transformOrigin: '225px 225px', animationDelay: '1s', },
  white1: { transformOrigin: '175px 275px', animationDelay: '2s', },
  black2: { transformOrigin: '175px 225px', animationDelay: '3s', },
  white2: { transformOrigin: '125px 225px', animationDelay: '4s', },
  black3: { transformOrigin: '225px 325px', animationDelay: '5s', },
  white3: { transformOrigin: '275px 225px', animationDelay: '6s', },
  black4: { transformOrigin: '225px 275px', animationDelay: '7s', },
  white4: { transformOrigin: '225px 375px', animationDelay: '8s', },
  black5: { transformOrigin: '125px 275px', animationDelay: '9s', },
  white5: { transformOrigin: '175px 175px', animationDelay: '10s', },
  black6: { transformOrigin: '225px 175px', animationDelay: '11s', },
  white6: { transformOrigin: '275px 125px', animationDelay: '12s', },
  black7: { transformOrigin: '225px 125px', animationDelay: '13s', },
  winAnimation: {
    transformOrigin: '225px 125px',
    animationDelay: '14s',
    transform: 'scale(0, 0)',
    animation: 'popup2 0.5s ease-in-out',
    animationFillMode: 'forwards',
  }
})

class RuleNo2 extends React.Component {
  constructor(props) {
    super(props)
    const keyframesStyle = `
      @keyframes popup {
        0%   { transform: scale(0, 0); }
        80%  { transform: scale(1.1, 1.1); }
        100% { transform: scale(1.0, 1.0); }
      }
    `
    const keyframesStyle2 = `
      @keyframes popup2 {
        0%   { transform: scale(0, 0); }
        100% { transform: scale(1.0, 1.0); }
      }
    `
    const styleElement = document.createElement('style');
    document.head.appendChild(styleElement);
    styleElement.sheet.insertRule(keyframesStyle, styleElement.sheet.cssRules.length)
    styleElement.sheet.insertRule(keyframesStyle2, styleElement.sheet.cssRules.length)
  }
  
  render() {
    const { classes, start } = this.props
    return (
      <svg viewBox='0 0 450 450'>
        <defs>
          <clipPath id='clip-path'>
            <rect className={classes.cls1} x='53' y='53' width='350' height='350' rx='16'/>
          </clipPath>
          <clipPath id='clip-path-2'>
            <rect className={classes.cls2} x='50' y='50' width='350' height='350' rx='16'/>
          </clipPath>
          <g id='black-cat'>
            <path className={classes.cls9} d='M95.74,65.8A18.79,18.79,0,0,0,96.85,59c0-3.35-.29-7.83-2.52-7.41a22.45,22.45,0,0,0-9.85,4.24,24,24,0,0,0-19.12.07,22.27,22.27,0,0,0-9.92-4.31c-2.23-.42-2.53,4.06-2.53,7.41a18.25,18.25,0,0,0,1.22,7A23.93,23.93,0,0,0,51,77.88c0,13.26,10.75,20.55,24,20.55s24-7.29,24-20.55A23.92,23.92,0,0,0,95.74,65.8Z' transform='translate(-24 -24)' />
            <circle className={classes.cls10} cx='40.56' cy='52.39' r='1.8' />
            <circle className={classes.cls10} cx='61.2' cy='52.39' r='1.8' />
            <path className={classes.cls10} d='M78.73,85.79a1.43,1.43,0,0,1-2.17-.24,5.49,5.49,0,0,1-.89-1.34V82.62h0a1.61,1.61,0,0,0,.82-1.41c0-.89-.69-1.17-1.59-1.17s-1.66.28-1.66,1.17a1.61,1.61,0,0,0,.87,1.44v1.72a.53.53,0,0,0,0,.13,4.63,4.63,0,0,1-.75,1.06,1.43,1.43,0,0,1-2.17.24c-.79-.61-1.57.75-.78,1.35,1.69,1.29,3.46.37,4.55-1.08,1.1,1.45,2.87,2.37,4.56,1.08a.8.8,0,0,0,.28-1.07A.79.79,0,0,0,78.73,85.79Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M94.91,84.86l-6.1-.51c-1-.08-1,1.48,0,1.56l6.1.51C95.91,86.5,95.91,84.94,94.91,84.86Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M88.82,83.5l6.09-.67c1-.11,1-1.67,0-1.56l-6.09.67C87.84,82,87.82,83.61,88.82,83.5Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M61,84.35l-6.1.51c-1,.08-1,1.64,0,1.56l6.1-.51C62,85.83,62,84.27,61,84.35Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M54.86,82.83,61,83.5c1,.11,1-1.46,0-1.56l-6.09-.67C53.87,81.16,53.87,82.72,54.86,82.83Z' transform='translate(-24 -24)' />
          </g>
          <g id='white-cat'>
            <path className={classes.cls10} d='M95.73,65.8A18.79,18.79,0,0,0,96.84,59c0-3.35-.29-7.83-2.52-7.41a22.45,22.45,0,0,0-9.85,4.24,24,24,0,0,0-19.12.07,22.27,22.27,0,0,0-9.92-4.31C53.2,51.17,52.9,55.65,52.9,59a18.25,18.25,0,0,0,1.22,7A23.93,23.93,0,0,0,51,77.88c0,13.26,10.75,20.55,24,20.55s24-7.29,24-20.55A23.92,23.92,0,0,0,95.73,65.8Z' transform='translate(-24 -24)' />
            <circle className={classes.cls9} cx='40.55' cy='52.39' r='1.8' />
            <circle className={classes.cls9} cx='61.19' cy='52.39' r='1.8' />
            <path className={classes.cls9} d='M78.72,85.79a1.43,1.43,0,0,1-2.17-.24,5.49,5.49,0,0,1-.89-1.34V82.62h0a1.61,1.61,0,0,0,.82-1.41c0-.89-.69-1.17-1.59-1.17s-1.66.28-1.66,1.17a1.61,1.61,0,0,0,.87,1.44v1.72a.53.53,0,0,0,0,.13,4.63,4.63,0,0,1-.75,1.06,1.43,1.43,0,0,1-2.17.24c-.79-.61-1.57.75-.78,1.35,1.69,1.29,3.46.37,4.55-1.08,1.1,1.45,2.87,2.37,4.56,1.08a.81.81,0,0,0,.28-1.07A.79.79,0,0,0,78.72,85.79Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M94.9,84.86l-6.1-.51c-1-.08-1,1.48,0,1.56l6.1.51C95.9,86.5,95.9,84.94,94.9,84.86Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M88.81,83.5l6.09-.67c1-.11,1-1.67,0-1.56l-6.09.67C87.83,82,87.81,83.61,88.81,83.5Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M60.94,84.35l-6.1.51c-1,.08-1,1.64,0,1.56l6.1-.51C61.94,85.83,62,84.27,60.94,84.35Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M54.85,82.83l6.09.67c1,.11,1-1.46,0-1.56l-6.09-.67C53.86,81.16,53.86,82.72,54.85,82.83Z' transform='translate(-24 -24)' />
          </g>
          <g id='win'>
            <path className={classes.cls12} d='M26.9,42.2c-3.45.75-5.52-1.5-7-3.5-.49,2.33-1.51,5.34-5,6.09a5.69,5.69,0,0,1-4.12-.66A11.05,11.05,0,0,1,7.22,40C6.16,38.15,4.87,35.66,4,33.89,2.66,31.15,1.73,29,1.1,27.38.77,26.56.55,25.86.37,25.3L.26,25l0-.08a5.73,5.73,0,0,1,3-7,5,5,0,0,1,1.28-.43,5.7,5.7,0,0,1,5.81,2.16,7.71,7.71,0,0,1,.93-1.94,5.26,5.26,0,0,1,3.55-2.44,5.34,5.34,0,0,1,4.34.77,8.25,8.25,0,0,1,1.6,1.34A5.75,5.75,0,0,1,25.11,13a4.83,4.83,0,0,1,1.41-.14A5.69,5.69,0,0,1,32,18a16.19,16.19,0,0,1,.25,2.68c.11,1.74.14,4,.08,7-.09,3.14-.23,5.35-.43,7l0,.36a10.13,10.13,0,0,1-1.42,4.82A5.76,5.76,0,0,1,26.9,42.2Z'/>
            <path className={classes.cls13} d='M21.27,36.42c-.73-1-1.61-2.39-2.53-3.83a.21.21,0,0,0-.37.08c-.21,1.69-.51,3.29-.72,4.53-.44,2.41-.92,4.67-3.25,5.17A3.28,3.28,0,0,1,12,42a8.91,8.91,0,0,1-2.67-3.27c-.91-1.54-2-3.71-3.18-5.94s-2.1-4.52-2.77-6.28c-.36-.88-.58-1.61-.77-2.21-.69-2.74.94-3.8,1.67-4.15A2.92,2.92,0,0,1,5,19.91c2.49-.54,3.81,1.69,5,3.65.36.6.81,1.34,1.21,2.18a.24.24,0,0,0,.4-.08c.16-1.07.28-2,.45-2.83a11,11,0,0,1,1.25-3.74,2.79,2.79,0,0,1,2-1.4,2.89,2.89,0,0,1,2.47.43A9.9,9.9,0,0,1,20.41,21c.51.7,1.06,1.48,1.61,2.39a.21.21,0,0,0,.37-.08c0-.94.13-1.8.2-2.49.25-2.27.56-4.85,3.05-5.38a2.76,2.76,0,0,1,.72-.1c.77,0,2.73.31,3.21,3.1a15.16,15.16,0,0,1,.21,2.33c.12,1.88.13,4.29.07,6.87s-.18,4.92-.41,6.71a9.23,9.23,0,0,1-1,4.08,3.36,3.36,0,0,1-2,1.34C24,40.29,22.73,38.41,21.27,36.42Z'/>
            <path className={classes.cls12} d='M41.76,39c-2.16.47-5-.28-6.55-3.79-.15-.38-.3-.81-.46-1.3-.11-.3-.22-.64-.35-1l0-.09-.08-.29c-.48-1.8-1.09-4-1.61-6.46s-.9-4.71-1.2-6.54l0-.3v-.1c0-.38-.06-.74-.1-1.06,0-.52-.09-1-.1-1.32-.06-3.89,2.23-5.75,4.39-6.22s5.1.28,6.57,3.85A23.88,23.88,0,0,1,43,16.76c.52,1.68,1.09,3.94,1.68,6.68s1,5,1.24,6.82a22.89,22.89,0,0,1,.22,2.5C46.29,36.62,43.87,38.52,41.76,39Z'/>
            <path className={classes.cls13} d='M40.63,17.44c.55,1.78,1.11,4.07,1.64,6.52s1,4.78,1.21,6.62a20.15,20.15,0,0,1,.2,2.27c.1,2.84-1.67,3.55-2.44,3.71s-2.62.25-3.76-2.37c-.21-.5-.43-1.25-.76-2.15-.48-1.79-1.1-4.06-1.63-6.52s-.91-4.79-1.21-6.62c-.08-.95-.18-1.73-.2-2.27,0-2.85,1.67-3.54,2.44-3.71s2.68-.26,3.76,2.37A19.35,19.35,0,0,1,40.63,17.44Z'/>
            <path className={classes.cls12} d='M68.2,33.39c-2.13.46-3.82.12-10.77-5.11.1.75.14,1.35.17,1.85l0,.35v.07c0,3.73-2.24,5.55-4.36,6s-5-.26-6.48-3.72c-.23-.54-.46-1.18-.73-1.93l-.18-.51,0-.1c-.38-1.4-1.09-4-1.66-6.62s-1-5-1.24-6.75v-.1l0-.52c-.06-.81-.12-1.5-.12-2.09-.1-3.07,1.64-5.53,4.34-6.11,2.2-.48,3.83-.15,10.83,5.12A14.88,14.88,0,0,1,57.75,11c-.07-3.78,2.19-5.63,4.34-6.09s5,.28,6.48,3.77a17.94,17.94,0,0,1,.83,2.46c.58,1.78,1.19,4.11,1.76,6.74s1,5,1.17,6.77a18.28,18.28,0,0,1,.25,2.33l0,.22A5.87,5.87,0,0,1,68.2,33.39Z'/>
            <path className={classes.cls13} d='M55.44,23.62a10.65,10.65,0,0,0-1.16-.9c-.12-.14-.33,0-.28.18.43,2,.7,3.84.92,5.3.15,1,.18,1.73.23,2.36,0,2.76-1.71,3.43-2.41,3.58s-2.61.27-3.68-2.26c-.25-.59-.51-1.33-.83-2.23-.47-1.7-1.11-4.07-1.63-6.49s-.93-4.88-1.22-6.68c-.08-1-.15-1.74-.14-2.29-.07-1.65.64-3.28,2.39-3.66,1.26-.28,2.27-.5,12.26,7.34a10.49,10.49,0,0,1,1.16.91c.14.06.34-.05.31-.2-.43-2-.75-3.92-.94-5.36a16.06,16.06,0,0,1-.2-2.27c-.07-2.84,1.68-3.51,2.39-3.67S65.16,7,66.3,9.63A15.65,15.65,0,0,1,67,11.79c.57,1.74,1.16,4.06,1.71,6.58s1,4.84,1.14,6.6a18,18,0,0,1,.25,2.35A3.42,3.42,0,0,1,67.67,31C66.48,31.23,65.43,31.46,55.44,23.62Z'/>
            <path className={classes.cls12} d='M80.72,32.9A6.38,6.38,0,0,1,74,23.2a7.37,7.37,0,0,1-1.57-1.74A9.32,9.32,0,0,1,71.3,19.4a45.72,45.72,0,0,1-1.83-4.86c-.62-1.9-1-3.76-1.31-5l0-.1c-.15-.87-.22-1.59-.26-2V7.24a7.1,7.1,0,0,1,5.84-7,6.83,6.83,0,0,1,8,4,9.6,9.6,0,0,1,.7,2.21A40.68,40.68,0,0,1,83.66,16.8,11.14,11.14,0,0,1,83.5,19a7.86,7.86,0,0,1-.7,2.28,6.39,6.39,0,0,1-2.08,11.6Z'/>
            <path className={classes.cls13} d='M81.19,16.76a9.1,9.1,0,0,1-.12,1.83c-.3,1.9-1.22,3-2.72,3.32A3.51,3.51,0,0,1,74.47,20a6.92,6.92,0,0,1-.9-1.61,40.44,40.44,0,0,1-1.73-4.6A48.05,48.05,0,0,1,70.58,9c-.12-.71-.2-1.34-.23-1.81a4.6,4.6,0,0,1,3.89-4.61A4.35,4.35,0,0,1,79.5,5.24,7.53,7.53,0,0,1,80,7a39.09,39.09,0,0,1,1.16,9.78Zm2,9.09a3.88,3.88,0,1,1-4.6-3A3.89,3.89,0,0,1,83.15,25.85Z'/>
          </g>
        </defs>
        <g className={classes.cls3}>
          <g id='Layer_1' data-name='Layer 1'>
            <g className={classes.cls7}>
              <line className={classes.cls8} x1='75' y1='25' x2='75' y2='425' />
              <line className={classes.cls8} x1='175' y1='25' x2='175' y2='425' />
              <line className={classes.cls8} x1='275' y1='25' x2='275' y2='425' />
              <line className={classes.cls8} x1='225' y1='25' x2='225' y2='425' />
              <line className={classes.cls8} x1='125' y1='25' x2='125' y2='425' />
              <line className={classes.cls8} x1='325' y1='25' x2='325' y2='425' />
              <line className={classes.cls8} x1='375' y1='25' x2='375' y2='425' />
              <line className={classes.cls8} x1='425' y1='75' x2='25' y2='75' />
              <line className={classes.cls8} x1='425' y1='225' x2='25' y2='225' />
              <line className={classes.cls8} x1='425' y1='325' x2='25' y2='325' />
              <line className={classes.cls8} x1='425' y1='125' x2='25' y2='125' />
              <line className={classes.cls8} x1='425' y1='175' x2='25' y2='175' />
              <line className={classes.cls8} x1='425' y1='275' x2='25' y2='275' />
              <line className={classes.cls8} x1='425' y1='375' x2='25' y2='375' />
            </g>
            <g className={classes.cls4}>
              <rect className={classes.cls1} x='53' y='53' width='350' height='350' rx='16'/>
            </g>
            <rect className={classes.cls2} x='50' y='50' width='350' height='350' rx='16'/>
            {start && (
              <React.Fragment>
                <use xlinkHref="#black-cat" x='175' y='175' className={classes.catAnimation + ' ' + classes.black1}/>
                <use xlinkHref="#white-cat" x='125' y='225' className={classes.catAnimation + ' ' + classes.white1}/>
                <use xlinkHref="#black-cat" x='125' y='175' className={classes.catAnimation + ' ' + classes.black2}/>
                <use xlinkHref="#white-cat" x='75'  y='175' className={classes.catAnimation + ' ' + classes.white2}/>
                <use xlinkHref="#black-cat" x='175' y='275' className={classes.catAnimation + ' ' + classes.black3}/>
                <use xlinkHref="#white-cat" x='225' y='175' className={classes.catAnimation + ' ' + classes.white3}/>
                <use xlinkHref="#black-cat" x='175' y='225' className={classes.catAnimation + ' ' + classes.black4}/>
                <use xlinkHref="#white-cat" x='175' y='325' className={classes.catAnimation + ' ' + classes.white4}/>
                <use xlinkHref="#black-cat" x='75'  y='225' className={classes.catAnimation + ' ' + classes.black5}/>
                <use xlinkHref="#white-cat" x='125' y='125' className={classes.catAnimation + ' ' + classes.white5}/>
                <use xlinkHref="#black-cat" x='175' y='125' className={classes.catAnimation + ' ' + classes.black6}/>
                <use xlinkHref="#white-cat" x='225' y='75'  className={classes.catAnimation + ' ' + classes.white6}/>
                <use xlinkHref="#black-cat" x='175' y='75'  className={classes.catAnimation + ' ' + classes.black7}/>
                <use xlinkHref="#win" x='125' y='60' className={classes.winAnimation}/>
              </React.Fragment>
            )}
          </g>
        </g>
      </svg>
    )
  }
}

export default withStyles(styles, { withTheme: true })(RuleNo2)

