import { types } from '../constants/ActionTypes'
import moment from 'moment'

const initialState = {
  posts: []
}

const wordpress = (state = initialState, action) => {
  switch (action.type) {
    case types.wordpress.RECEIVE_CUSTOM_POSTS: {
      let newPosts = Object.assign([], action.response)
      let findFunc = n1 => n2 => n2.id === n1.id
      state.posts.forEach(n1 => {
        if (newPosts.some(findFunc(n1)) === false) {
          newPosts.push(n1)
        }
      })
      newPosts = newPosts.sort((a, b) => {
        return moment(a.date).valueOf() < moment(b.date).valueOf() ? 1 :
               moment(a.date).valueOf() > moment(b.date).valueOf() ? -1 : 0
      })
      return { ...state, posts: newPosts }
    }
    default:
      return state
  }
}

export default wordpress