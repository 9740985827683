import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  svg: {
    borderRadius: theme.spacing.unit * 2,
    border: `4px solid ${theme.palette.common.white}`,
    boxShadow: `2px 2px 0px 2px #00000020`,
    margin: theme.spacing.unit,
  },
  cls1: {
    fill: 'none',
  },
  cls2: {
    clipPath: 'url(#clip-path)',
  },
  cls3: {
    fill: '#000000',
  },
  cls4: {
    fill: '#ffffff',
  },
  cls5: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    width: 900,
    textAnchor: 'middle',
    fill: theme.palette.common.white,
    fontSize: '70px',
  }
})

class RuleHeader extends React.Component {
  render() {
    const { classes, margin } = this.props

    const styles = {
      svg: {
        margin: margin,
      }
    }

    return (
      <svg viewBox='54 0 892 292' className={classes.svg} style={styles.svg}>
        <path className={classes.cls3} d='M212.49,107.83c3-12.8,1.55-29.7-.48-41.22-3.49-19.8-9.9-45.89-22.61-41.07-20.47,7.78-41,20.17-53.63,35.23A144,144,0,0,0,23.09,81.05C6,71-17.76,66.29-39.89,66c-13.59-.19-10.69,26.53-7.2,46.33,2.11,12,6.76,29.14,14.48,40.12a143.15,143.15,0,0,0-6.14,73.2C-25,303.78,46,335.62,124.14,321.84s133.93-68,120.15-146.13A143.09,143.09,0,0,0,212.49,107.83Z' transform='translate(50)' />
        <circle className={classes.cls4} cx='89.68' cy='202.68' r='10.78' />
        <circle className={classes.cls4} cx='211.39' cy='181.22' r='10.78' />
        <path className={classes.cls4} d='M133,243.41c-4.17,4.51-8.27,4.38-13,.82a30.24,30.24,0,0,1-6.63-7l-.1-.14-1.63-9.2s0-.05,0-.08a9.69,9.69,0,0,0,3.36-9.15c-.93-5.29-5.28-6.19-10.57-5.26s-9.5,3.35-8.57,8.63a9.73,9.73,0,0,0,6.61,7.57l1.8,10.17a4.33,4.33,0,0,0,.19.75,29.55,29.55,0,0,1-3.31,7c-3.27,5-7.08,6.5-12.54,3.68s-8.52,6-3.24,8.77c11.32,5.84,20.81-1.39,25.74-11.09,8,7.43,19.35,11,28,1.61,1.72-1.87,2.29-4.47.54-6.58C138.12,242.19,134.7,241.56,133,243.41Z' transform='translate(50)' />
        <path className={classes.cls4} d='M227.45,221.07,191,224.44c-6,.55-4.33,9.75,1.62,9.2l36.49-3.37C235.06,229.72,233.4,220.52,227.45,221.07Z' transform='translate(50)' />
        <path className={classes.cls4} d='M190.11,219.39l35.19-10.25c5.71-1.66,4.15-10.88-1.62-9.2l-35.19,10.25C182.78,211.85,184.34,221.07,190.11,219.39Z' transform='translate(50)' />
        <path className={classes.cls4} d='M26.67,253.41l-35.44,9.31c-5.77,1.52-4.2,10.73,1.63,9.2l35.43-9.32C34.07,261.09,32.5,251.87,26.67,253.41Z' transform='translate(50)' />
        <path className={classes.cls4} d='M-10.83,250.78l36.57-2.41c6-.39,4.32-9.59-1.62-9.2l-36.57,2.41C-18.44,242-16.77,251.17-10.83,250.78Z' transform='translate(50)' />
        <path className={classes.cls4} d='M933.45,150.55c7.22-11,11.61-27.36,13.64-38.87,3.49-19.78,6.39-46.48-7.19-46.29-21.89.31-45.4,4.92-62.4,14.75A143.92,143.92,0,0,0,764.77,60.67c-12.57-15.26-33.31-27.82-54-35.68-12.69-4.82-19.1,21.26-22.59,41-2.11,11.94-3.61,29.68-.11,42.62a142.86,142.86,0,0,0-30.78,66.63c-13.78,78.1,42,132.24,120.05,146s149-18,162.76-96.14A142.87,142.87,0,0,0,933.45,150.55Z' transform='translate(50)' />
        <circle className={classes.cls3} cx='788.78' cy='180.54' r='10.77' />
        <circle className={classes.cls3} cx='910.39' cy='201.98' r='10.77' />
        <path className={classes.cls3} d='M812.48,250.68C807,253.49,803.22,252,800,247a30.31,30.31,0,0,1-3.84-8.8,1.09,1.09,0,0,1-.05-.17l1.62-9.19a.22.22,0,0,1,0-.08A9.67,9.67,0,0,0,804,221.3c.94-5.28-2.84-7.61-8.12-8.54s-10.07-.11-11,5.17a9.68,9.68,0,0,0,3.62,9.36l-1.79,10.17a4.08,4.08,0,0,0-.07.77,30.13,30.13,0,0,1-5.5,5.44c-4.77,3.57-8.88,3.68-13-.82S758,245.6,762,250c8.64,9.36,20,5.82,28-1.61,4.93,9.7,14.41,16.92,25.72,11.08,2.26-1.16,3.68-3.42,2.76-6C817.71,251.28,814.72,249.52,812.48,250.68Z' transform='translate(50)' />
        <path className={classes.cls3} d='M908.8,262l-35.41-9.31c-5.81-1.52-7.4,7.68-1.62,9.19l35.41,9.32C913,272.7,914.58,263.5,908.8,262Z' transform='translate(50)' />
        <path className={classes.cls3} d='M874.32,247.64l36.54,2.41c5.93.39,7.61-8.8,1.62-9.19l-36.54-2.41C870,238.06,868.33,247.25,874.32,247.64Z' transform='translate(50)' />
        <path className={classes.cls3} d='M709.23,223.73l-36.46-3.36c-5.93-.55-7.61,8.64-1.62,9.19l36.46,3.36C713.55,233.47,715.23,224.28,709.23,223.73Z' transform='translate(50)' />
        <path className={classes.cls3} d='M674.92,208.45l35.16,10.23c5.76,1.68,7.33-7.53,1.62-9.19l-35.16-10.24C670.78,197.58,669.21,206.78,674.92,208.45Z' transform='translate(50)' />
        <text className={classes.cls5} x='504' y='185'>あそびかた</text>
        <rect className={classes.cls1} x='54' y='4' width='892' height='292' rx='16'/>
      </svg>
    )
  }
}

export default withStyles(styles, { withTheme: true })(RuleHeader)