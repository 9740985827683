import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { Board, Word } from '../../containers';
import { PlayNavigation, RadioButtonGroup } from './Common'
import './NyankoPuzzlePage.css'
import { puzzleLevelProps } from '../../constants';
import Engine from '../../lib/Engine';
import { WID } from '../../constants/Language';
import { makePath } from '../../lib';


const PUZZLE_MODE = {
  TIME_ATTACK: 'timeattack'
}

const PUZZLE_COLOR = {
  BLACK: 'black',
  WHITE: 'white',
}

const modeLabelProps = [
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.TIME_ATTACK, value: PUZZLE_MODE.TIME_ATTACK },
]

const colorLabelProps = [
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.BLACK_CAT, value: PUZZLE_COLOR.BLACK },
  { wid: WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.WHITE_CAT, value: PUZZLE_COLOR.WHITE },
]

const stylesForSettingsSection = theme => ({
  root: {
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: theme.spacing.unit * 5,
    borderRadius: theme.spacing.unit,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 5px 0px 0px #00000022`,
    userSelect: 'none',
    margin: '0 auto',
    color: theme.palette.primary.main,
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow: 'none',
      transform: 'translate(0px,1px)',
    },
  },
  buttonLabel: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1em',
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.common.white,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.3em',
    textAlign: 'center',
  },
  grid: {
    marginBottom: theme.spacing.unit * 3,
  }
})

class SettingsSection extends React.Component {
  state = {
    mode: PUZZLE_MODE.TIME_ATTACK,
    color: PUZZLE_COLOR.BLACK,
  }

  handleClickMode = (value) => {
    this.setState({ mode: value })
  }

  handleClickColor = (value) => {
    this.setState({ color: value })
  }

  handleClickStart = (event) => {
    const { mode, color } = this.state
    this.props.onClickStart(mode, color)
  }

  render() {
    const { classes } = this.props
    const { mode, color } = this.state

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item sm={6} xs={12} className={classes.grid}>
            <Typography className={classes.subtitle}><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.SELECT_TYPE}/></Typography>
            <RadioButtonGroup labelProps={modeLabelProps} selected={mode} onClick={this.handleClickMode} center/>
          </Grid>
          <Grid item sm={6} xs={12} className={classes.grid}>
            <Typography className={classes.subtitle}><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.SELECT_CAT}/></Typography>
            <RadioButtonGroup labelProps={colorLabelProps} selected={color} onClick={this.handleClickColor} center/>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.button} onClick={this.handleClickStart}>
              <Typography className={classes.buttonLabel} color='inherit'><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.START}/></Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SettingsSection = withStyles(stylesForSettingsSection, { withTheme: true })(SettingsSection)

const nyankoPuzzleRankProps = [
  { rank: 'E',   min:   0,  max:  9 },
  { rank: 'D',   min:  10,  max: 19 },
  { rank: 'C',   min:  20,  max: 29 },
  { rank: 'B',   min:  30,  max: 39 },
  { rank: 'A',   min:  40,  max: 49 },
  { rank: 'S',   min:  50,  max: 59 },
  { rank: 'SS',  min:  60,  max: 99 },
  { rank: 'SSS', min: 100,  max: 1000 },
]

const stylesForPlaySection = theme => ({
  play: {
    animation: 'fadein-section 0.3s linear',
    animationFillMode: 'forwards',
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  answerLabel: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1em',
  },
  answer: {
    width: 70,
    textAlign: 'right',
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.7em',
    marginRight: theme.spacing.unit * 3,
  },
  timeLabel: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1em',
  },
  time: {
    width: 70,
    textAlign: 'right',
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.7em',
  },
})

class PlaySection extends React.Component {
  timeLimit = 60000
  state = {
    level: 0,
    targetIndex: -1,
    targetLevelPuzzles: [],
    answer: undefined,
    correct: 0,
    incorrect: 0,
    result: '',
    startTime: 0,
    time: Math.round(this.timeLimit / 1000),
    interval: -1,
    finishedPuzzle: undefined,
    status: 'play',
    rank: '',
  }
  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentWillReceiveProps(nextProps) {
    const { puzzles, color } = nextProps
    const { level } = this.state
    var targetLevelPuzzles, targetIndex

    if (puzzles) {
      targetLevelPuzzles = puzzles.filter(puzzle => puzzle.level === level && puzzle.next_color === color)
      if (targetLevelPuzzles.length > 0 && this.state.targetIndex < 0) {
        while (targetIndex === undefined || targetIndex === this.state.targetIndex) {
          targetIndex = targetLevelPuzzles[Math.floor(Math.random() * targetLevelPuzzles.length)].index 
        }
        this.setState({ targetIndex, targetLevelPuzzles, startTime: Date.now(), interval: setInterval(this.countTime, 100), finishedPuzzle: undefined })
      }
    }
  }

  countTime = () => {
    const { startTime, correct, interval } = this.state
    var time, rankProps, rank
    if (this._isMounted === false) {
      return
    }
    time = Math.round((this.timeLimit + startTime - Date.now() + correct * 3000) / 1000)
    if (time < 0) {
      clearInterval(interval)
      rankProps = nyankoPuzzleRankProps.find(props => props.min <= correct && correct <= props.max)
      rank = rankProps === undefined ? 'SSS' : rankProps.rank
      this.setState({ time: 0, status: 'result', finishedPuzzle: true, rank })
      this.props.onFinish(correct)
    }
    else {
      time = time < 0 ? 0 : time
      if (time !== this.state.time) {
        this.setState({ time })
      }
    }
  }

  handleClickBoard = (x, y) => {
    const { puzzles, mode, color, onCorrect } = this.props
    const { targetIndex, finishedPuzzle } = this.state
    var moves, answer, result, correct, incorrect, engine, calls, level, targetLevelPuzzles
    if (this._isMounted === false || this.state.answer !== undefined || finishedPuzzle === true) {
      return
    }
    moves = this.getTargetPuzzleMoves(puzzles, targetIndex)
    answer = this.getTargetPuzzleAnswer(puzzles, targetIndex)
    if (moves === undefined || moves.some(move => move.x === x && move.y === y)) {
      return
    }
    if (answer !== undefined && answer.x === x && answer.y === y) {
      correct = this.state.correct + 1
      incorrect = this.state.incorrect
      result = 'correct'
      onCorrect(correct, puzzles, mode, color, this.state.level)
    }
    else {
      correct = this.state.correct
      incorrect = this.state.incorrect + 1
      result = 'incorrect'
    }

    engine = new Engine()
    engine.setMoves(moves, color === PUZZLE_COLOR.BLACK ? 'blackcat' :　color === PUZZLE_COLOR.WHITE ? 'whitecat' : undefined)
    engine.checkLine()
    calls = engine.getCalls(x, y)

    level = puzzleLevelProps.length <= parseInt(correct / 10, 10) ? puzzleLevelProps.length - 1 : parseInt(correct / 10, 10)
    targetLevelPuzzles = this.state.targetLevelPuzzles.filter(puzzle => puzzle.index !== targetIndex)
    if (targetLevelPuzzles.length === 0 || level !== this.state.level) {
      targetLevelPuzzles = puzzles.filter(puzzle => puzzle.level === level && puzzle.next_color === color)
    }
    setTimeout(this.handleTimeout, 2000)

    this.setState({
      answer: {
        x, y,
        color:
          color === PUZZLE_COLOR.BLACK ? 'blackcat' :
          color === PUZZLE_COLOR.WHITE ? 'whitecat' : undefined,
      },
      calls,
      correct,
      incorrect,
      result,
      level,
      targetLevelPuzzles
    })
  }

  handleTimeout = () => {
    const { puzzles } = this.props
    var targetIndex
    if (this._isMounted === false) {
      return
    }
    if (puzzles) {
      targetIndex = this.state.targetLevelPuzzles[Math.floor(Math.random() * this.state.targetLevelPuzzles.length)].index
      this.setState({
        targetIndex,
        answer: undefined,
        calls: [],
        result: '',
      })
    }
  }


  board = {
    handleClickRetry: () => {
      const { puzzles, color } = this.props
      var targetLevelPuzzles, targetIndex

      if (puzzles) {
        targetLevelPuzzles = puzzles.filter(puzzle => puzzle.level === 0 && puzzle.next_color === color)
        if (targetLevelPuzzles.length > 0) {
          targetIndex = targetLevelPuzzles[Math.floor(Math.random() * targetLevelPuzzles.length)].index 
          this.setState({
            level: 0,
            targetIndex,
            targetLevelPuzzles,
            startTime: Date.now(),
            interval: setInterval(this.countTime, 100),
            finishedPuzzle: undefined,
            answer: undefined,
            correct: 0,
            incorrect: 0,
            result: '',
            time: this.timeLimit / 1000,
            status: 'play',
          })
        }
      }
    },
    handleClickWatch: () => {
      this.setState({
        status: 'watch',
      })
    }
  }

  getTargetPuzzleMoves(puzzles, targetIndex) {
    var targetPuzzle, moves
    if (puzzles) {
      targetPuzzle = puzzles.find(puzzle => puzzle.index === targetIndex)
      if (targetPuzzle) {
        return targetPuzzle.moves
      }
    }
    return moves
  }

  getTargetPuzzleAnswer(puzzles, targetIndex) {
    var targetPuzzle
    if (puzzles) {
      targetPuzzle = puzzles.find(puzzle => puzzle.index === targetIndex)
      if (targetPuzzle) {
        return targetPuzzle.answer
      }
    }
    return undefined
  }

  render() {
    const { classes, puzzles, color } = this.props
    const { targetIndex, answer, calls, result, time, finishedPuzzle, correct, incorrect, rank, status } = this.state
    var moves, afterComponent
    moves = this.getTargetPuzzleMoves(puzzles, targetIndex)
    if (answer !== undefined) {
      moves = moves.concat(answer)
    }
    if (finishedPuzzle === true && status !== 'watch') {
      afterComponent = <ResultSVG color={color} correct={correct} incorrect={incorrect} rank={rank} onClickRetry={this.board.handleClickRetry} onClickWatch={this.board.handleClickWatch}/>
    }

    return (
      <div className={classes.play}>
        <div className={classes.infoBox}>
          <Typography className={classes.answerLabel} color='inherit'><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.CORRECT_NUMBER}/></Typography>
          <Typography className={classes.answer} color='inherit'>{correct}</Typography>
          <Typography className={classes.timeLabel} color='inherit'><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.TIME}/></Typography>
          <Typography className={classes.answer} color='inherit'>{time}</Typography>
        </div>
        <Board
          moves={moves}
          catKey={targetIndex}
          calls={calls}
          result={result}
          afterComponent={afterComponent}
          onClick={this.handleClickBoard}
        />
    </div>
    )
  }
}

const stylesForResultSVG = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  label: {
    fontSize: '80px',
    fontWeight: 800,
    fill: '#ffffff',
    fontFamily: 'heisei-maru-gothic-std',
    letterSpacing: '0.05em'
  },
  value: {
    fontSize: '80px',
    fontWeight: 800,
    fill: '#ffffff',
    fontFamily: 'heisei-maru-gothic-std',
    textAnchor: 'end'
  },
  buttonLabel: {
    fontSize: '40px',
    fontWeight: 800,
    fill: theme.palette.primary.main,
    fontFamily: 'heisei-maru-gothic-std',
    textAnchor: 'middle',
    dominantBaseline: 'central',
  }
})

class ResultSVG extends React.Component {
  render() {
    const { classes, color, correct, incorrect, rank, onClickRetry, onClickWatch } = this.props
    return (
      <svg className={`nyanko-puzzle-result-svg ${classes.root}`} viewBox='0 0 1500 1500'>
        <rect className={'result-background'} x='-50' y='450' width='1600' height='600' fill={'#8fc31fcc'} stroke='#fff' strokeMiterlimit='10' strokeWidth='20'/>
        <use className={'result-cat'} xlinkHref={`#${color}cat`} x={-100} y={500}/>
        <text className={`${classes.label} result-title`} x='700'  y='600'><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.TITLE}/></text>
        <text className={`${classes.label} result-item-correct`} x='800'  y='725'><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.CORRECT}/></text>
        <text className={`${classes.value} result-correct`} x='1400' y='725'>{correct}</text>
        <text className={`${classes.label} result-item-incorrect`} x='800'  y='850'><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.INCORRECT}/></text>
        <text className={`${classes.value} result-incorrect`} x='1400' y='850'>{incorrect}</text>
        <text className={`${classes.label} result-item-rank`} x='800'  y='975'><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.RANK}/></text>
        <text className={`${classes.value} result-rank`} x='1400' y='975'>{rank}</text>
        <rect className={'retry-button'} x='100' y='1200' rx='50' width='600' height='100' fill={'#ffffff'} onClick={onClickRetry}/>
        <text className={`retry-button ${classes.buttonLabel}`} x='400' y='1250' onClick={onClickRetry}><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.RETRY}/></text>
        <rect className={'watch-button'} x='800' y='1200' rx='50' width='600' height='100' fill={'#ffffff'} onClick={onClickWatch}/>
        <text className={`watch-button ${classes.buttonLabel}`} x='1100' y='1250' onClick={onClickWatch}><Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.RESULT.WATCH}/></text>
      </svg>
    )
  }
}

ResultSVG = withStyles(stylesForResultSVG, { withTheme: true })(ResultSVG)

PlaySection = withStyles(stylesForPlaySection, { withTheme: true })(PlaySection)

const NYNAKO_PUZZLE_STATUS = {
  SETTINGS: 'settings',
  PLAY: 'play',
  RESULT: 'result',
  WATCH: 'watch',
}

const styles = theme => ({
  root: {
  },
})

class NyankoPuzzlePage extends React.Component {
  state = {
    status: NYNAKO_PUZZLE_STATUS.SETTINGS,
    mode: PUZZLE_MODE.TIME_ATTACK,
    color: PUZZLE_COLOR.BLACK,
  }
  
  handleClickStart = (mode, color) => {
    this.setState({ status: NYNAKO_PUZZLE_STATUS.PLAY, level: 0, mode, color })
    this.props.handleClickStart(this.props.puzzles, mode, color, 0)
  }

  handleBack = () => {
    this.setState({ status: NYNAKO_PUZZLE_STATUS.SETTINGS })
  }

  render() {
    const { puzzles, language, highScore, handleCorrect } = this.props
    const { status, mode, color } = this.state

    return (
      <React.Fragment>
        {status === NYNAKO_PUZZLE_STATUS.SETTINGS && (
          <React.Fragment>
            <PlayNavigation title={<Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.TITLE}/>} back={makePath('/play/puzzle', language)}/>
            <SettingsSection mode={mode} color={color} onClickStart={this.handleClickStart}/>
          </React.Fragment>
        )}
        {(status === NYNAKO_PUZZLE_STATUS.PLAY || status === NYNAKO_PUZZLE_STATUS.WATCH) && (
          <React.Fragment>
            <PlayNavigation title={<Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.TITLE}/>} onClick={this.handleBack}/>
            <PlaySection {...{ status, mode, color, puzzles, onCorrect: handleCorrect, onFinish: this.props.handleFinish(highScore) }}/>
          </React.Fragment>
        )}
      </React.Fragment>
		)
  }
}

export default withStyles(styles, { withTheme: true })(NyankoPuzzlePage)
