import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  cls1: {
    fill: 'none',
    stroke: '#00000022',
    strokeMiterlimit: 10,
    strokeWidth: 8,
  },
  cls2: {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: 8,
  },
  cls3: {
    isolation: 'isolate',
  },
  cls4: {
    mixBlendMode: 'multiply',
  },
  cls5: {
    clipPath: 'url(#clip-path)',
  },
  cls6: {
    fill: 'none',
    stroke: '#00000022',
    strokeMiterlimit: 10,
    strokeLinecap: 'round',
    strokeWidth: 2,
  },
  cls7: {
    clipPath: 'url(#clip-path-2)',
  },
  cls8: {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: 2,
  },
  cls9: {
    fill: '#040000',
  },
  cls10: {
    fill: '#fff',
  },
  cls11: {
    fill: 'none',
  },
  black1: {
    transformOrigin: '225px 225px',
    transform: 'scale(0, 0)',
    animation: 'popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
    animationDelay: '1s',
  },
  black2: {
    transformOrigin: '175px 225px',
    transform: 'scale(0, 0)',
    animation: 'popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
    animationDelay: '3s',
  },
  black3: {
    transformOrigin: '225px 325px',
    transform: 'scale(0, 0)',
    animation: 'popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
    animationDelay: '5s',
  },
  white1: {
    transformOrigin: '175px 275px',
    transform: 'scale(0, 0)',
    animation: 'popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
    animationDelay: '2s',
  },
  white2: {
    transformOrigin: '125px 225px',
    transform: 'scale(0, 0)',
    animation: 'popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
    animationDelay: '4s',
  },
  white3: {
    transformOrigin: '275px 225px',
    transform: 'scale(0, 0)',
    animation: 'popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
    animationDelay: '6s',
  },
})

class RuleNo1 extends React.Component {
  constructor(props) {
    super(props)
    const keyframesStyle = `
      @keyframes popup {
        0%   { transform: scale(0, 0); }
        80%  { transform: scale(1.1, 1.1); }
        100% { transform: scale(1.0, 1.0); }
      }
    `
    const styleElement = document.createElement('style');
    document.head.appendChild(styleElement);
    styleElement.sheet.insertRule(keyframesStyle, styleElement.sheet.cssRules.length)
  }

  render() {
    const { classes, start } = this.props
    return (
      <svg viewBox='0 0 450 450'>
        <defs>
          <clipPath id='clip-path'>
            <rect className={classes.cls1} x='53' y='53' width='350' height='350' rx='16'/>
          </clipPath>
          <clipPath id='clip-path-2'>
            <rect className={classes.cls2} x='50' y='50' width='350' height='350' rx='16'/>
          </clipPath>
          <g id='black-cat'>
            <path className={classes.cls9} d='M95.74,65.8A18.79,18.79,0,0,0,96.85,59c0-3.35-.29-7.83-2.52-7.41a22.45,22.45,0,0,0-9.85,4.24,24,24,0,0,0-19.12.07,22.27,22.27,0,0,0-9.92-4.31c-2.23-.42-2.53,4.06-2.53,7.41a18.25,18.25,0,0,0,1.22,7A23.93,23.93,0,0,0,51,77.88c0,13.26,10.75,20.55,24,20.55s24-7.29,24-20.55A23.92,23.92,0,0,0,95.74,65.8Z' transform='translate(-24 -24)' />
            <circle className={classes.cls10} cx='40.56' cy='52.39' r='1.8' />
            <circle className={classes.cls10} cx='61.2' cy='52.39' r='1.8' />
            <path className={classes.cls10} d='M78.73,85.79a1.43,1.43,0,0,1-2.17-.24,5.49,5.49,0,0,1-.89-1.34V82.62h0a1.61,1.61,0,0,0,.82-1.41c0-.89-.69-1.17-1.59-1.17s-1.66.28-1.66,1.17a1.61,1.61,0,0,0,.87,1.44v1.72a.53.53,0,0,0,0,.13,4.63,4.63,0,0,1-.75,1.06,1.43,1.43,0,0,1-2.17.24c-.79-.61-1.57.75-.78,1.35,1.69,1.29,3.46.37,4.55-1.08,1.1,1.45,2.87,2.37,4.56,1.08a.8.8,0,0,0,.28-1.07A.79.79,0,0,0,78.73,85.79Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M94.91,84.86l-6.1-.51c-1-.08-1,1.48,0,1.56l6.1.51C95.91,86.5,95.91,84.94,94.91,84.86Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M88.82,83.5l6.09-.67c1-.11,1-1.67,0-1.56l-6.09.67C87.84,82,87.82,83.61,88.82,83.5Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M61,84.35l-6.1.51c-1,.08-1,1.64,0,1.56l6.1-.51C62,85.83,62,84.27,61,84.35Z' transform='translate(-24 -24)' />
            <path className={classes.cls10} d='M54.86,82.83,61,83.5c1,.11,1-1.46,0-1.56l-6.09-.67C53.87,81.16,53.87,82.72,54.86,82.83Z' transform='translate(-24 -24)' />
          </g>
          <g id='white-cat'>
            <path className={classes.cls10} d='M95.73,65.8A18.79,18.79,0,0,0,96.84,59c0-3.35-.29-7.83-2.52-7.41a22.45,22.45,0,0,0-9.85,4.24,24,24,0,0,0-19.12.07,22.27,22.27,0,0,0-9.92-4.31C53.2,51.17,52.9,55.65,52.9,59a18.25,18.25,0,0,0,1.22,7A23.93,23.93,0,0,0,51,77.88c0,13.26,10.75,20.55,24,20.55s24-7.29,24-20.55A23.92,23.92,0,0,0,95.73,65.8Z' transform='translate(-24 -24)' />
            <circle className={classes.cls9} cx='40.55' cy='52.39' r='1.8' />
            <circle className={classes.cls9} cx='61.19' cy='52.39' r='1.8' />
            <path className={classes.cls9} d='M78.72,85.79a1.43,1.43,0,0,1-2.17-.24,5.49,5.49,0,0,1-.89-1.34V82.62h0a1.61,1.61,0,0,0,.82-1.41c0-.89-.69-1.17-1.59-1.17s-1.66.28-1.66,1.17a1.61,1.61,0,0,0,.87,1.44v1.72a.53.53,0,0,0,0,.13,4.63,4.63,0,0,1-.75,1.06,1.43,1.43,0,0,1-2.17.24c-.79-.61-1.57.75-.78,1.35,1.69,1.29,3.46.37,4.55-1.08,1.1,1.45,2.87,2.37,4.56,1.08a.81.81,0,0,0,.28-1.07A.79.79,0,0,0,78.72,85.79Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M94.9,84.86l-6.1-.51c-1-.08-1,1.48,0,1.56l6.1.51C95.9,86.5,95.9,84.94,94.9,84.86Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M88.81,83.5l6.09-.67c1-.11,1-1.67,0-1.56l-6.09.67C87.83,82,87.81,83.61,88.81,83.5Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M60.94,84.35l-6.1.51c-1,.08-1,1.64,0,1.56l6.1-.51C61.94,85.83,62,84.27,60.94,84.35Z' transform='translate(-24 -24)' />
            <path className={classes.cls9} d='M54.85,82.83l6.09.67c1,.11,1-1.46,0-1.56l-6.09-.67C53.86,81.16,53.86,82.72,54.85,82.83Z' transform='translate(-24 -24)' />
          </g>
        </defs>
        <g className={classes.cls3}>
          <g className={classes.cls7}>
            <line className={classes.cls8} x1='75' y1='25' x2='75' y2='425' />
            <line className={classes.cls8} x1='175' y1='25' x2='175' y2='425' />
            <line className={classes.cls8} x1='275' y1='25' x2='275' y2='425' />
            <line className={classes.cls8} x1='225' y1='25' x2='225' y2='425' />
            <line className={classes.cls8} x1='125' y1='25' x2='125' y2='425' />
            <line className={classes.cls8} x1='325' y1='25' x2='325' y2='425' />
            <line className={classes.cls8} x1='375' y1='25' x2='375' y2='425' />
            <line className={classes.cls8} x1='425' y1='75' x2='25' y2='75' />
            <line className={classes.cls8} x1='425' y1='225' x2='25' y2='225' />
            <line className={classes.cls8} x1='425' y1='325' x2='25' y2='325' />
            <line className={classes.cls8} x1='425' y1='125' x2='25' y2='125' />
            <line className={classes.cls8} x1='425' y1='175' x2='25' y2='175' />
            <line className={classes.cls8} x1='425' y1='275' x2='25' y2='275' />
            <line className={classes.cls8} x1='425' y1='375' x2='25' y2='375' />
          </g>
          <g className={classes.cls4}>
            <rect className={classes.cls1} x='53' y='53' width='350' height='350' rx='16'/>
          </g>
          <rect className={classes.cls2} x='50' y='50' width='350' height='350' rx='16'/>
          {start && (
            <React.Fragment>
              <use xlinkHref="#black-cat" x='175' y='175' className={classes.black1}/>
              <use xlinkHref="#black-cat" x='125' y='175' className={classes.black2}/>
              <use xlinkHref="#black-cat" x='175' y='275' className={classes.black3}/>
              <use xlinkHref="#white-cat" x='125' y='225' className={classes.white1}/>
              <use xlinkHref="#white-cat" x='75'  y='175' className={classes.white2}/>
              <use xlinkHref="#white-cat" x='225' y='175' className={classes.white3}/>
            </React.Fragment>
          )}
        </g>
      </svg>
    )
  }
}

export default withStyles(styles, { withTheme: true })(RuleNo1)