import { connect } from 'react-redux'
import { NyankoPuzzlePage } from '../../components'
import * as actions from '../../actions'
import { puzzleLevelProps } from '../../constants'
import LocalDB from '../../lib/LocalDB';

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  const { language } = state.settings
  const { puzzles, highScore } = state.puzzle.nyanko
  return ({
    width,
    height,
    puzzles,
    language,
    highScore,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    handleClickStart: (puzzles, mode, color, level) => {
      let { lower_limit, upper_limit } = puzzleLevelProps[level]
      dispatch(actions.puzzle.nyanko.fetchSelect(level, color, lower_limit, upper_limit))
    },
    handleCorrect: (correct, puzzles, mode, color, level) => {
      if (puzzleLevelProps.length - 1 === parseInt(correct / 10, 10)) {
        return
      }
      if (
        correct % 10 === 8 &&
        puzzles.every(puzzle => puzzle.color !== color || puzzle.level !== level)
      ) {
        let { lower_limit, upper_limit } = puzzleLevelProps[level]
        dispatch(actions.puzzle.nyanko.fetchSelect(level + 1, color, lower_limit, upper_limit))
      }
    },
    handleFinish: (highScore) => (correct) => {
      dispatch(actions.puzzle.nyanko.updateHighScore(correct))
      LocalDB.getToken()
      .then(token => {
        if (token !=='') {
          dispatch(actions.puzzle.nyanko.fetchUpdateHighScore(token, correct))
        }
      })
    }
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NyankoPuzzlePage)
