import { connect } from 'react-redux'
import { TopicsSection } from '../../components'
import { POST_TYPES } from '../../constants'
import * as actions from '../../actions'
import moment from 'moment'

const mapStateToProps = (state, ownProps) => {
  const { width } = state.window
  const { language } = state.settings
  const targetPostTypes = [ POST_TYPES.NEWS, POST_TYPES.EVENTS ]
  const posts = state.wordpress.posts
    .filter(post => targetPostTypes.some(type => post.type === type))
    .sort((a, b) => moment(a.date).valueOf() < moment(b.date).valueOf())
  return ({
    posts,
    width,
    language,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    fetchTopics: (callback) => {
      let types = [ POST_TYPES.NEWS, POST_TYPES.EVENTS ]
      Promise.all(types.map(type => {
        return dispatch(actions.wordpress.fetchCustomPosts(type, '?_embed'))
      }))
      .then(response => {
        if (callback !== undefined) {
          callback(true)
        }
      })
      .catch(error => {
        if (callback !== undefined) {
          callback(false)
        }
        console.dir(error)
      })
    }
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicsSection)
