const dbVersion = 1
const dbName = 'nyankonarabe'
const storeName = 'nyankonarabeLocalStorage'

const LocalDB = {
  initialize(callback) {
    return new Promise((resolve, reject) => {
      var openReq = indexedDB.open(dbName, dbVersion)
      openReq.onupgradeneeded = (event) => {
        let db = event.target.result
        let keyStore = db.createObjectStore(storeName, { keyPath: 'key' })
        keyStore.put({ key: 'token', value: '' })
      }
      openReq.onsuccess = (event) => {
        let db = event.target.result
        db.close()
        resolve(event)
      }
      openReq.onerror = function(event){
        let db = event.target.result
        db.close()
        reject(event)
      }
    })
  },
  getToken() {
    return new Promise((resolve, reject) => {
      var openReq = indexedDB.open(dbName)
      openReq.onsuccess = (event) => {
        var db = event.target.result
        let trans = db.transaction(storeName, 'readonly')
        let store = trans.objectStore(storeName)
        let getReq = store.get('token')
        getReq.onsuccess = (event) => {
          let token = event.target.result !== undefined ? event.target.result.value : ''
          resolve(token)
          db.close()
        }
        getReq.onerror = (event) => {
          db.close()
        }
      }
    })
  },
  setToken(token) {
    return new Promise((resolve, reject) => {
      var openReq = indexedDB.open(dbName)
      openReq.onsuccess = (event) => {
        var db = event.target.result
        let trans = db.transaction(storeName, 'readwrite')
        let store = trans.objectStore(storeName)
        let setReq = store.put({ key: 'token', value: token })
        setReq.onsuccess = (event) => {
          db.close()
          resolve()
        }
        setReq.onerror = (event) => {
          db.close()
          reject('error')
        }
      }
    })
  },
}

export default LocalDB