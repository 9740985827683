import { connect } from 'react-redux'
import { GoodsHeader } from '../../components'

const mapStateToProps = (state, ownProps) => {
  return ({
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoodsHeader)
