import { connect } from 'react-redux'
import { Word } from '../../components'
import { withRouter } from 'react-router'

const mapStateToProps = (state, ownProps) => {
  const { language } = state.settings
  return ({
    shortCode: language
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Word))
