import { connect } from 'react-redux'
import { GoodsTopPage } from '../../components'
import * as actions from '../../actions'
import { POST_TYPES } from '../../constants';

const mapStateToProps = (state, ownProps) => {
  const { width, height } = state.window
  const { language } = state.settings
  var posts = state.wordpress.posts.filter(post => post.type === POST_TYPES.GOODS)
  return ({
    width,
    height,
    language,
    posts,
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return ({
    mount: () => {
      dispatch(actions.wordpress.fetchCustomPosts(POST_TYPES.GOODS, `?per_page=100&_embed`))
    }
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoodsTopPage)
