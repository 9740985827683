import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardContent, CardMedia, Typography, Fade, Grid, ButtonBase, Button } from '@material-ui/core'
import { CalendarToday as CalendarTodayIcon } from '@material-ui/icons'
import { CatPiece as CatPieceIcon } from '../Icons'
import { PostTypeTag } from '../Common'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { POST_TYPES } from '../../constants';
import { Word } from '../../containers';
import { WID } from '../../constants/Language';
import { makePath } from '../../lib';

const stylesForTopicsCard = theme => ({
  buttonBase: {
    width: '100%',
  },
  card: {
    boxShadow: 'none',
    borderRadius: 0,
    height: 300,
    width: '100%',
  },
  cardMedia: {
    height: 200,
    objectFit: 'cover'
  },
  cardNonMedia: {
    height: 200,
    width: '100%',
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
  },
  tagAndDate: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit,
  },
  date: {
    color: theme.palette.grey[500],
    fontSize: '0.7rem',
  },
  eventDateBox: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing.unit / 2}px 0`
  },
  calendarIcon: {
    fontSize: '0.875em',
    marginRight: theme.spacing.unit,
  }
})

const TopicsCard = withStyles(stylesForTopicsCard, { withTheme: true })((props) => {
  const { classes, post, language } = props
  var visibleCardMedia = Boolean(
    post._embedded !== undefined &&
    post._embedded['wp:featuredmedia'] !== undefined &&
    post._embedded['wp:featuredmedia'].length > 0 &&
    post._embedded['wp:featuredmedia'][0].source_url !== undefined
  )
  
  return (
    <ButtonBase component={Link} to={makePath(`/topics/${post.type}/${post.slug}`, language)} className={classes.buttonBase}>
      <Card className={classes.card}>
        {visibleCardMedia ? (
          <CardMedia
            className={classes.cardMedia}
            image={post._embedded['wp:featuredmedia'][0].source_url}
            component='img'
          />
        ) : (
          <div className={classes.cardNonMedia}/>
        )}
        <CardContent>
          <div className={classes.tagAndDate}>
            <PostTypeTag type={post.type}/>
            <div className={classes.date}>{moment(post.date).format('YYYY.MM.DD')}</div>
          </div>
          <Typography noWrap className={classes.title}>{post.title.rendered}</Typography>
          {post.type === POST_TYPES.EVENTS && post.meta.start_date && (
            <div className={classes.eventDateBox}>
              <CalendarTodayIcon color='inherit' className={classes.calendarIcon}/>
              <Typography color='inherit'>{moment(post.meta.start_date, 'YYYYMMDD').format('YYYY.MM.DD')}</Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </ButtonBase>
  )
})

const styles = theme => ({
  section: {
    backgroundColor: theme.palette.grey[100],
    paddingBottom: theme.spacing.unit * 4,
  },
  content: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
  },
  card: {
    backgroundColor: 'inherit',
    boxShadow: 'none',
    borderRadius: 0,
  },
  cardHeaderTitle: {
    textAlign: 'center',
  },
  titleGridBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${theme.spacing.unit * 5}px 0`,
  },
  title: {
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1em',
  },
  icon: {
    marginRight: theme.spacing.unit * 2
  },
  topicsGridBox: {
    width: `calc(100% - ${theme.spacing.unit}px)`,
    height: 300,
    margin: '0 auto',
  },
  sectionTitle: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
  },
  moreButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 4,
  },
  moreButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing.unit * 4,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    boxShadow: `2px 2px 0px 2px ${theme.palette.grey[300]}`,
    maxWidth: 200,
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
    }
  }
})

class TopicsSecion extends React.Component {
  state = {
    fetched: false,
    visibleSection: false,
  }
  _isMounted = false

  componentWillMount() {
    this.props.fetchTopics((result) => {
      if (this._isMounted === false){
        return
      }
      this.setState({ fetched: true })
    })
  }

  componentDidMount() {
    this._isMounted = true
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (wd, event) => {
    if (this._isMounted === false) {
      return
    }
    var elem, rect
    elem = document.getElementById('topics-section')
    if (elem === null) {
      return
    }
    rect = elem.getBoundingClientRect()
    if (this.state.visibleSection === false && rect.height * 1.0 - (window.innerHeight - rect.top) < 0) {
      this.setState({ visibleSection: true })
    }
  }

  render() {
    const { classes, theme, posts, width, language } = this.props
    const { visibleSection, fetched } = this.state
    const newsNum =
      theme.breakpoints.values.md <= width ? 4 :
      theme.breakpoints.values.sm <= width ? 3 : 2
    return(
      <div className={classes.section} id='topics-section'>
        <Fade in={visibleSection && fetched} timeout={1000}>
          <div className={classes.content}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.titleGridBox}>
                  <div className={classes.icon}>
                    <CatPieceIcon type='white' width={50}/>
                  </div>
                  <Typography variant='h6' className={classes.sectionTitle}><Word wid={WID.HOME_PAGE.TOPICS.TITLE}/></Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Fade in={posts.length > 0}>
                  <div className={classes.topicsGridBox}>
                    <Grid container spacing={8}>
                      {posts
                      .filter((post, index) => 3 <= index && index < newsNum + 3)
                      .map(post => {
                        return (
                          <Grid item md={3} sm={4} xs={6} key={post.id}>
                            <TopicsCard post={post} language={language}/>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </div>
                </Fade>
              </Grid>
            </Grid>
            <div className={classes.moreButtonBox}>
              <Button
                variant='outlined'
                className={classes.moreButton}
                component={Link}
                to={makePath('/topics', language)}
              >
                <Word wid={WID.HOME_PAGE.TOPICS.MORE}/>
              </Button>
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(TopicsSecion)
