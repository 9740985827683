import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { PROFILE_SYNC_STATUS } from '../../constants'
import { Typography, Grid, Select, MenuItem, IconButton } from '@material-ui/core'
import { CalendarTodayRounded as CalendarTodayIcon, CachedRounded as CachedIcon, EqualizerRounded as EqualizerIcon, Refresh as RefreshIcon } from '@material-ui/icons'

const stylesForTabs = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  tabButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit,
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[200],
    height: '100%',
    borderBottom: `3px solid ${theme.palette.grey[200]}`
  },
  selected: {
    borderBottom: `3px solid ${theme.palette.primary.main}`
  },
})

const Tabs = withStyles(stylesForTabs, { withTheme: true })((props) => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={4}>
          <div className={`${classes.tabButton} ${classes.selected}`}>
            <EqualizerIcon color='inherit'/>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.tabButton}>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.tabButton}>
          </div>
        </Grid>
      </Grid>
    </div>
  )
})

const RANKING_TYPE = {
  ALL: 'all',
  DAILY: 'daily',
}

const rankingTypeMenuItemProps = [
  { value: RANKING_TYPE.ALL, label: 'All' },
  { value: RANKING_TYPE.DAILY, label: 'Daily' }
]

const stylesForRankingBox = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  controlBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    marginBottom: theme.spacing.unit,
  },
  rankingPlayerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing.unit / 2,
  },
  icon: {
    width: '0.875em',
    height: '0.875em',
  }
})

class RankingBox extends React.Component {
  state = {
    term: RANKING_TYPE.ALL,
  }

  handleChange = (event) => {
    this.setState({ term: event.target.value })
  }

  render() {
    const { classes, ranking, onRefresh } = this.props
    const { term } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.controlBox}>
          <IconButton onClick={onRefresh}>
            <RefreshIcon className={classes.icon}/>
          </IconButton>
          <Select
            value={term}
            onChange={this.handleChange}
            className={classes.select}
          >
            {rankingTypeMenuItemProps.map((props, index) => {
              return (
                <MenuItem value={props.value} key={index}>{props.label}</MenuItem>
              )
            })}
          </Select>
        </div>
        {ranking[term].map((player, index) => {
          return (
            <div className={classes.rankingPlayerBox} key={index}>
              <Typography>{`#${index + 1}`}</Typography>
              <Typography>{player.name}</Typography>
              <Typography>{player.score}</Typography>
            </div>
          )
        })}
      </div>
    )
  }
}

RankingBox = withStyles(stylesForRankingBox, { withTheme: true })(RankingBox)

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing.unit,
    maxWidth: 700,
    margin: '0 auto',
  },
  titleBox: {
    padding: theme.spacing.unit * 2,
    borderRadius: `${theme.spacing.unit}px ${theme.spacing.unit}px 0 0`,
    backgroundColor: theme.palette.grey[200],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  title: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
    textAlign: 'center',
  },
  nyankoPuzzleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontFamily: 'heisei-maru-gothic-std, sans-serif',
    color: theme.palette.grey[800],
  },
  scoreBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 2,
    color: theme.palette.grey[500],
  },
  detailScoreBox: {
    textAlign: 'center',
    width: '100%',
    maxWidth: 130,
    fontSize: '1em',
  },
  score: {
    color: theme.palette.grey[800],
    fontSize: '2em',
    textAlign: 'center',
  },
})

class NyankoPuzzleScoreCard extends React.Component {
  componentDidMount() {
    this.props.handleDidMount()
  }

  render() {
    const { classes, highScore, ranking, syncStatus, handleRefreshRanking } = this.props

    return syncStatus === PROFILE_SYNC_STATUS.FINISH ? (
      <div className={classes.root}>
        <div className={classes.titleBox}>
          <Typography className={classes.title}>にゃんこパズル</Typography>
        </div>
        <div className={classes.scoreBox}>
          <div className={classes.detailScoreBox}>
            <CalendarTodayIcon color='inherit' fontSize='inherit'/>
            <Typography color='inherit'>今日</Typography>
            <Typography className={classes.score}>{highScore.daily >=0 ? highScore.daily : '-'}</Typography>
          </div>
          <div className={classes.detailScoreBox}>
            <CachedIcon color='inherit' fontSize='inherit'/>
            <Typography color='inherit'>全期間</Typography>
            <Typography className={classes.score}>{highScore.all >= 0 ? highScore.all : '-'}</Typography>
          </div>
        </div>
        <Tabs/>
        <RankingBox ranking={ranking} onRefresh={handleRefreshRanking}/>
      </div>
		) : <React.Fragment></React.Fragment>
  }
}

export default withStyles(styles, { withTheme: true })(NyankoPuzzleScoreCard)
