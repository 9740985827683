import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { RuleNo1, RuleNo2, RuleNo3 } from '../Icons'
import { Typography, Grid, Fade } from '@material-ui/core'
import { Word } from '../../containers';
import { WID } from '../../constants/Language';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		paddingTop: theme.spacing.unit * 14,
	},
	page: {
		width: '100%',
		maxWidth: 900,
		margin: `0 auto`,
	},
	titleBox: {
		width: '100%',
		maxWidth: 900,
		display: 'flex',
		position: 'relative',
		justifyContent: 'center',
		alignItems: 'center',
		margin: `${theme.spacing.unit * 6}px auto`,
	},
	line: {
		width: '100%',
		height: theme.spacing.unit / 2,
		maxWidth: 884,
		backgroundColor: theme.palette.common.white,
		borderRadius: theme.spacing.unit / 4,
		margin: `0 ${theme.spacing.unit}px`,
	},
	title: {
		position: 'absolute',
		fontSize: '2em',
		marign: '0 auto',
		padding: theme.spacing.unit * 3,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
		fontWeight: 400,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
  detailGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ruleHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    width: 200,
    height: 40,
    maxWidth: 200,
    backgroundColor: theme.palette.common.white,
    margin: '0 auto',
  },
  ruleHeaderTypo: {
    color: theme.palette.primary.main,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1em',
  },
  detailBox: {
    marginTop: theme.spacing.unit * 2,
  },
  detailTypo: {
		color: theme.palette.common.white,
		fontFamily: 'heisei-maru-gothic-std, sans-serif',
		fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1em',
	},
	rule3Grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
		marginBottom: theme.spacing.unit * 2,
	},
	rule3Typography: {
		marginLeft: theme.spacing.unit * 2,
	}
})

class RulePage extends React.Component {
  state = {
		visible: {
			rule1: false,
			rule2: false,
			ruleExNya: false,
			ruleExNyan: false,
			ruleExNyanko: false,
		},
		rule1: 0,
		rule2: 0,
  }
	  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
	}
	
	handleScroll = (wd, event) => {
		var elem, rect, visible
		const elementIds = [ 'rule1', 'rule2', 'ruleExNya', 'ruleExNyan', 'ruleExNyanko' ]
		visible = {}

		elementIds.forEach(id => {
			elem = document.getElementById(id)
			if (elem === null) {
				visible[id] = false
				return
			}
			rect = elem.getBoundingClientRect()
			if (this.state.visible[id] === false && rect.height * 0.8 - (window.innerHeight - rect.top) < 0) {
				visible[id] = true
			}
		})
		this.setState({ visible: { ...this.state.visible, ...visible } })
	}

	handleClickSVG = (key) => () => {
		this.setState({ [key]: this.state[key] + 1 })
	}
	
  render() {
		const { classes, theme, width } = this.props
		const { visible, rule1, rule2 } = this.state
    const styles = {
		}

    return (
			<div className={classes.root} style={styles.root}>
				<div className={classes.page} style={styles.page}>
					<Grid container>
						<Grid item xs={12} sm={12} className={classes.detailGrid}>
              <div>
                <div className={classes.ruleHeader}>
                  <Typography className={classes.ruleHeaderTypo}><Word wid={WID.RULE_PAGE.RULE_1.TITLE}/></Typography>
                </div>
                <div className={classes.detailBox}>
                  <Typography className={classes.detailTypo}><Word wid={WID.RULE_PAGE.RULE_1.DESCRIPTION}/></Typography>
                </div>
              </div>
						</Grid>
						<Grid item xs={12} sm={12} onClick={this.handleClickSVG('rule1')}>
							<div id='rule1'>
								<RuleNo1 start={visible.rule1} key={rule1}/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} className={classes.detailGrid}>
              <div>
                <div className={classes.ruleHeader}>
                  <Typography className={classes.ruleHeaderTypo}><Word wid={WID.RULE_PAGE.RULE_2.TITLE}/></Typography>
                </div>
                <div className={classes.detailBox}>
                  <Typography className={classes.detailTypo}><Word wid={WID.RULE_PAGE.RULE_2.DESCRIPTION}/></Typography>
                </div>
              </div>
						</Grid>
						<Grid item xs={12} sm={12} onClick={this.handleClickSVG('rule2')}>
							<div id='rule2'>
								<RuleNo2 start={visible.rule2} key={rule2}/>
							</div>
						</Grid>
						<Grid item xs={12} className={classes.rule3Grid}>
							<div>
								<div className={classes.ruleHeader}>
									<Typography className={classes.ruleHeaderTypo}><Word wid={WID.RULE_PAGE.RULE_EX.TITLE}/></Typography>
								</div>
								<div className={classes.detailBox}>
									<Typography className={classes.detailTypo}><Word wid={WID.RULE_PAGE.RULE_EX.DESCRIPTION}/></Typography>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={5}>
							<div id='ruleExNya'>
								<Fade in={visible.ruleExNya}>
									<div>
										<div className={classes.rule3Typography}>
												<Typography className={classes.detailTypo}><Word wid={WID.RULE_PAGE.RULE_EX.THREE_HEADER}/></Typography>
										</div>
										<RuleNo3 type='nya'/>
									</div>
								</Fade>
							</div>
						</Grid>
						<Grid item xs={12} sm={7}>
							<div id='ruleExNyan' style={{marginTop: width < theme.breakpoints.values.sm ? 0 : theme.spacing.unit * 2}}>
								<Fade in={visible.ruleExNyan}>
									<div>
										<div className={classes.rule3Typography}>
											<Typography className={classes.detailTypo}><Word wid={WID.RULE_PAGE.RULE_EX.FOUR_HEADER}/></Typography>
										</div>
										<RuleNo3 type='nyan'/>
									</div>
								</Fade>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div id='ruleExNyanko'>
								<Fade in={visible.ruleExNyanko}>
									<div>
										<div className={classes.rule3Typography}>
											<Typography className={classes.detailTypo}><Word wid={WID.RULE_PAGE.RULE_EX.FIVE_HEADER}/></Typography>
										</div>
										<RuleNo3 type='nyanko'/>
									</div>
								</Fade>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>
		)
  }
}

export default withStyles(styles, { withTheme: true })(RulePage)
