import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, TextField, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import { PROFILE_SYNC_STATUS, errorUserManagerProps } from '../../constants';

const stylesForRegisterDialog = theme => ({
  textField: {
    imeMode: 'inactive',
  },
  buttonLabel: {
    color: theme.palette.common.white,
  },
})

class RegisterDialog extends React.Component {
  state = {
    name: '',
    error: false,
    message: '',
  }

  handleChange = (name) => (event) => {
    if (event.target.value.match(/^[a-zA-Z0-9]{0,12}$/g) === null) {
      return
    }
    this.setState({ [name]: event.target.value, error: false, message: '' })
  }

  handleRegister = (name) => () => {
    this.props.onRegister(name)
    .then(response => {
      if (response.success === true) {
        this.props.onClose()
      }
      else if (response.error !== undefined) {
        let errorProps = errorUserManagerProps.find(props => props.value === response.error)
        if (errorProps !== undefined) {
          this.setState({ error: true, message: errorProps.wid })
        }
        else {
          console.dir(response.error)
        }
      }
    })
  }

  render() {
    const { classes, open, onClose } = this.props
    const { name, message, error } = this.state

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>SignIn</DialogTitle>
        <DialogContent>
          <TextField
            id='outlined-name'
            label='なまえ'
            className={classes.textField}
            value={name}
            onChange={this.handleChange('name')}
            margin='normal'
            helperText={message}
            error={error}
            type='text'
          />
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant='text' onClick={onClose}>Cancel</Button>
          <Button
            classes={{ label: classes.buttonLabel }}
            color='primary'
            variant='contained'
            disabled={Boolean(name.length < 4)}
            onClick={this.handleRegister(name)}
          >
            登録
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

RegisterDialog = withStyles(stylesForRegisterDialog, { withTheme: true })(RegisterDialog)

const LogoIcon = (props) => {
  const { color, ...other } = props
  return (
    <svg viewBox='0 0 100 100' {...other}>
      <circle cx='50' cy='50' r='50' fill={color} />
      <path d='M73.76,44.53A21.42,21.42,0,0,0,75,36.75c0-3.85-.34-9-2.89-8.49-4.12.79-8.4,2.44-11.28,4.86A27.52,27.52,0,0,0,39,33.19c-2.88-2.46-7.21-4.14-11.37-4.93-2.55-.49-2.9,4.64-2.9,8.49a20.87,20.87,0,0,0,1.4,8A27.42,27.42,0,0,0,22.5,58.38c0,15.19,12.31,23.55,27.5,23.55s27.5-8.36,27.5-23.55A27.34,27.34,0,0,0,73.76,44.53Z' fill='#fff' />
    </svg>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 700,
    width: '100%',
    margin: `${theme.spacing.unit * 4}px auto ${theme.spacing.unit * 2}px auto`,
    overflow: 'hidden',
    borderRadius: theme.spacing.unit,
  },
  spaceBetweenBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userNameBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing.unit * 2,
  },
  avatar: {
    marginRight: theme.spacing.unit,
  },
  userName: {
    fontFamily: 'heisei-maru-gothic-std',
  },
  signinButton: {
    margin: theme.spacing.unit * 2,
  }
})

class UserCard extends React.Component {
  state = {
    registerDialog: {
      open: false,
    }
  }

  handleSignIn = () => {
    this.setState({ registerDialog: { open: true } })
  }

  registerDialog = {
    onClose: () => {
      this.setState({ registerDialog: { open: false } })
    },
    onRegister: (name) => {
      return this.props.handleClickRegister(name)
    },
  }

  render() {
    const { classes, theme, user } = this.props

    return user.syncStatus !== PROFILE_SYNC_STATUS.FINISH ? (
      <React.Fragment></React.Fragment>
    ) : user.name === '' ? (
      <div className={classes.root}>
        <div className={classes.spaceBetweenBox}>
          <div className={classes.userNameBox}>
            <LogoIcon className={classes.avatar} width={32} height={32} color={theme.palette.grey[500]}/>
            <Typography className={classes.userName}>Guest</Typography>
          </div>
          <Button className={classes.signinButton} variant='outlined' onClick={this.handleSignIn}>サインイン</Button>
        </div>
        <RegisterDialog open={this.state.registerDialog.open} {...this.registerDialog} />
      </div>
		) : (
      <div className={classes.root}>
        <div className={classes.userNameBox}>
          <LogoIcon className={classes.avatar} width={32} height={32} color={theme.palette.primary.main}/>
          <Typography className={classes.userName}>{user.name}</Typography>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(UserCard)
