import React from 'react'

class CatPiece extends React.Component {
  render() {
    const { width, height, type } = this.props
    const styles = {
      cls1 : {
        fill: type === 'black' ? '#4d4d4d' :
              type === 'white' ? '#d5d5d5' : undefined
      },
      cls2: {
        fill: type === 'black' ? '#070001' :
              type === 'white' ? '#ffffff' : undefined
      },
      cls3: {
        fill: type === 'black' ? '#ffffff' :
              type === 'white' ? '#4d4d4d' : undefined
      }
    }
    return (
      <svg width={width} height={height} viewBox='0 0 95 82.77'>
        <path style={styles.cls1} d='M96.86,61.61l.61-12.3h-5a36.66,36.66,0,0,0-8.72-9.25c.57-3-.34-6.83-1.29-9.44-1.63-4.5-4.33-10.4-8.09-9.14-6.07,2-12,5.13-15.48,8.74a58.79,58.79,0,0,0-17.84.34,49.39,49.39,0,0,0-16.19,5.7,34.68,34.68,0,0,0-5-1.45L20,32.72S7.79,20.84,5.72,20.88a2.77,2.77,0,0,0-2.53,2.19L2.6,35.77h0a8.37,8.37,0,0,0,0,2.64A22.15,22.15,0,0,0,4,43.57c.08.24.16.47.25.7a25.48,25.48,0,0,0,4.62,8.06l-.32,6.8a22.82,22.82,0,0,0-.12,2.49l0,.61.06.08A25.55,25.55,0,0,0,10,70.14c6.42,17.74,29.1,24.16,52.71,20C81,87,94.53,78.35,96.63,66.34h0v0A19.27,19.27,0,0,0,96.86,61.61Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls2} d='M96.05,42.53A33.73,33.73,0,0,0,84.37,27.36c.57-3-.34-6.82-1.29-9.44C81.46,13.43,78.76,7.53,75,8.79c-6.07,2-12,5.13-15.48,8.74a58.78,58.78,0,0,0-17.84.33,49.94,49.94,0,0,0-16.19,5.7C20,21.47,12.53,20.67,5.72,20.88c-4.17.12-2.53,6.2-.91,10.7,1,2.71,2.9,6.59,5.58,9a24.78,24.78,0,0,0,.16,16.85c6.42,17.74,29.09,24.17,52.7,20S102.47,60.27,96.05,42.53Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls3} d='M33.11,48.81c-1.77.31-2.82,1.64-2.33,3s2.3,2.16,4.07,1.85,2.82-1.64,2.34-3S34.88,48.5,33.11,48.81Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls3} d='M69.88,42.4c-1.77.31-2.82,1.63-2.34,3s2.31,2.15,4.08,1.85,2.82-1.64,2.34-3S71.65,42.09,69.88,42.4Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls3} d='M65.81,59.45a1.79,1.79,0,0,0-2,0c-1.16,1.08-2.42,1.1-4,.35a9.64,9.64,0,0,1-2.23-1.52l0,0-.76-2.1v0A1.83,1.83,0,0,0,57.54,54c-.43-1.2-1.8-1.36-3.39-1.08s-2.83.89-2.39,2.08A2.88,2.88,0,0,0,54,56.62l.83,2.32a.8.8,0,0,1,.08.16,5.51,5.51,0,0,1-.81,1.65c-.87,1.18-2,1.58-3.75,1s-2.45,1.49-.75,2a6.08,6.08,0,0,0,7.59-2.86c2.65,1.6,6.25,2.28,8.64,0A.91.91,0,0,0,65.81,59.45Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls3} d='M92.15,53.13,81,54.35c-1.82.2-1.06,2.28.76,2.09l11.1-1.23C94.73,55,94,52.93,92.15,53.13Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls3} d='M80.64,53.2l10.52-2.78c1.7-.45,1-2.54-.76-2.09L79.88,51.11C78.18,51.56,78.91,53.66,80.64,53.2Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls3} d='M31.41,63,20.79,65.58c-1.73.42-1,2.51.76,2.08l10.62-2.57C33.9,64.67,33.16,62.58,31.41,63Z' transform='translate(-2.5 -8.62)' />
        <path style={styles.cls3} d='M31,61.86c1.83-.16,1.06-2.25-.75-2.08l-11.16,1c-1.83.16-1.06,2.25.75,2.09Z' transform='translate(-2.5 -8.62)' />
      </svg>
    )
  }
}

export default CatPiece