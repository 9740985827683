import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import './Board.css'
import { Word } from '../../containers';
import { WID } from '../../constants/Language';

const stylesForCat = theme => ({})

const Cat = withStyles(stylesForCat, { withTheme: true })((props) => {
  const { theme, type } = props
  const styles = {
    component1: {
      fill: type === 'blackcat' ? theme.palette.common.black : theme.palette.common.white
    },
    component2: {
      fill: type === 'blackcat' ? theme.palette.common.white : theme.palette.common.black
    }
  }
  return (
    <g id={type}>
      <path style={styles.component1} d='M91.47,30.77c1.74-4,2.24-9.68,2.24-13.59,0-6.71-.6-15.67-5.06-14.82C81.47,3.73,74,6.62,69,10.84A48.11,48.11,0,0,0,30.72,11c-5-4.29-12.57-7.22-19.84-8.61-4.46-.85-5.05,8.11-5.05,14.82,0,4.06.53,10,2.44,14A47.71,47.71,0,0,0,2,54.94C2,81.45,23.49,96,50,96s48-14.59,48-41.1A47.75,47.75,0,0,0,91.47,30.77Z'/>
      <circle style={styles.component2} cx='29.13' cy='51.94' r='3.6' />
      <circle style={styles.component2} cx='70.41' cy='51.94' r='3.6' />
      <path style={styles.component2} d='M57.46,70.75c-1.63,1.25-3,1-4.34-.48a10,10,0,0,1-1.77-2.68l0,0V64.39A3.23,3.23,0,0,0,53,61.58c0-1.79-1.38-2.34-3.18-2.34s-3.32.55-3.32,2.34a3.24,3.24,0,0,0,1.74,2.87V67.9a2.11,2.11,0,0,0,0,.26,10.17,10.17,0,0,1-1.49,2.11c-1.37,1.45-2.71,1.73-4.34.48s-3.15,1.5-1.58,2.7c3.39,2.58,6.93.75,9.12-2.16C52.11,74.2,55.65,76,59,73.45a1.63,1.63,0,0,0,.56-2.14A1.58,1.58,0,0,0,57.46,70.75Z'/>
      <path style={styles.component2} d='M89.83,68.89l-12.2-1c-2-.17-2,3,0,3.12l12.2,1C91.83,72.17,91.82,69.05,89.83,68.89Z'/>
      <path style={styles.component2} d='M77.64,66.17l12.17-1.33c2-.22,2-3.34,0-3.12L77.64,63.05C75.67,63.26,75.65,66.38,77.64,66.17Z'/>
      <path style={styles.component2} d='M21.91,67.88l-12.2,1c-2,.16-2,3.28,0,3.12l12.2-1C23.89,70.83,23.91,67.71,21.91,67.88Z'/>
      <path style={styles.component2} d='M9.72,64.84l12.17,1.33c2,.21,2-2.91,0-3.12L9.72,61.72C7.73,61.5,7.75,64.62,9.72,64.84Z'/>
    </g>
  )
})

const stylesForFukidashi = theme => ({
  text: {
    fontSize: '30px',
    fontWeight: 800,
    fill: '#231815',
    fontFamily: 'heisei-maru-gothic-std',
    letterSpacing: '0.05em',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    transform: 'rotate(-5deg)',
  }
})

const Fukidashi = withStyles(stylesForFukidashi)((props) => {
  const { classes, id, word } = props
  return (
    <g id={id}>
      <path d='M8,144.37l13-30.47C-12.88,85.47-4.87,42.14,38.81,17.11S145.3-5.17,179.09,23.26s25.78,71.77-17.9,96.79c-32.24,18.48-76.4,22.37-111.6,9.83Z' fill='#fff' fillRule='evenodd' />
      <text x='95' y='82' className={classes.text}>{word}</text>
    </g>
  )
})

const stylesForResultOfMove = theme => ({})

const ResultOfMove = withStyles(stylesForResultOfMove)((props) => {
  const { id, word, mark, color } = props
  return (
    <g id={id}>
      <rect x='0' y='0' width='1000' height='200' rx='100' fill={color} stroke='#fff' strokeMiterlimit='10' strokeWidth='20' />
      {mark === 'check' ? (
        <path transform='translate(-50 0)' d='M150.15,131.29l-26.91-26.91a7.74,7.74,0,0,0-10.94,10.94l32.42,32.41a7.71,7.71,0,0,0,10.93,0l82-82a7.73,7.73,0,0,0-10.94-10.93Z' fill='#fff' />
      ) : mark === 'cross' ? (
        <path transform='translate(-50 0)' d='M225.63,51.75a8,8,0,0,0-11.33,0L175,91,135.7,51.67A8,8,0,0,0,124.37,63l39.3,39.29-39.3,39.3a8,8,0,0,0,11.33,11.33L175,113.63l39.3,39.29a8,8,0,0,0,11.33-11.33l-39.3-39.3L225.63,63A8.06,8.06,0,0,0,225.63,51.75Z' fill='#fff' />
      ) : undefined}
      <text transform="translate(550 149.88)" style={{ fontSize: '125px', fill: '#fff', fontFamily: 'heisei-maru-gothic-std', fontWeight: 800, textAnchor: 'middle' }}>{word}</text>
    </g>
  )
})


const stylesForAnimationFukidashi= theme => ({
  fukidashi: {
    transform: 'scale(0, 0)',
    animation: 'fukidashi-popup 1.2s ease-in-out',
    animationFillMode: 'backwards',
  }
})

class AnimationFukidashi extends React.Component {
  state = {
    visible: false,
  }
  handleNumber = -1

  componentDidMount() {
    const { delay } = this.props
    this.handleNumber = setTimeout(this.timeout, delay)
  }
  
  componentWillUnmount() {
    const { visible } = this.state
    if (visible === false) {
      clearTimeout(this.handleNumber)
    }
  }

  timeout = () => {
    this.setState({ visible: true })
  }

  render() {
    const { classes, call, x, y } = this.props
    const { visible } = this.state
    return visible ? (
      <use
        xlinkHref={`#${call}`}
        className={classes.fukidashi}
        x={(x + 1) * 100}
        y={(y - 1) * 100}
        style={{ transformOrigin: `${(x + 1) * 100}px ${y * 100}px` }}
      />
    ) : (<React.Fragment/>)
  }
}

AnimationFukidashi = withStyles(stylesForAnimationFukidashi, { withTheme: true })(AnimationFukidashi)


const styles = theme => ({
  root: {
    position: 'relative',
    margin: `0 ${-theme.spacing.unit}px`
  },
  board: {
    userSelect: 'none',
  },
  line: {
    fill: 'none',
    stroke: '#fff',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 5,
  },
  flower1: {
    fill: '#f2d522',
  },
  flower2: {
    fill: '#fff',
  },
  flower: {
    animation: 'flower-rotate 20s ease-in-out infinite',
    animationFillMode: 'forwards',
  },
  cat: {
    transform: 'scale(0, 0)',
    animation: 'cat-popup 0.3s ease-in-out',
    animationFillMode: 'forwards',
  },
  resultOfMove: {
    animation: 'result-popup 1.2s ease-in-out',
    animationFillMode: 'forwards',
  },
  svgButtons: {
    position: 'absolute',
    top: 0,
    left: 0,
    userSelect: 'none',
  },
  buttonLabel: {
    fontSize: '40px',
    fontWeight: 800,
    fill: theme.palette.primary.main,
    fontFamily: 'heisei-maru-gothic-std',
    textAnchor: 'middle',
    dominantBaseline: 'central',
  }
})

class Board extends React.Component {
  handleClick = (event) => {
    var element, scale, cell, clientRect, positionX, positionY, x, y
    element = document.getElementById('board')
    scale = element.clientWidth / 1500
    cell = 100 * scale
    clientRect = element.getBoundingClientRect()
    positionX = event.pageX - clientRect.left - window.pageXOffset
    positionY = event.pageY - clientRect.top - window.pageYOffset
    x = parseInt(positionX / cell, 10)
    y = parseInt(positionY / cell, 10)
    if (this.props.onClick !== undefined) {
      this.props.onClick(x, y)
    }
  }

  handleRightClick = (event) => {
    if (this.props.onRightClick !== undefined) {
      this.props.onRightClick()
    }
    event.preventDefault()
  }

  render() {
    const { classes, theme, moves, catKey, calls, result, afterComponent } = this.props
    const lineNum = 15
    const lineProps = []
    const flowerPos = [{ x: 3, y: 3 }, { x: 11, y: 3 }, { x: 7, y: 7 }, { x: 3, y: 11 }, { x: 11, y: 11 }]
    const flowerProps = []
    for (var index = 0; index < lineNum; index++){
      lineProps.push({ x1: 50 + index * 100, y1: 50, x2: 50 + index * 100, y2: 1450 })
      lineProps.push({ x1: 50, y1: 50 + index * 100, x2: 1450, y2: 50 + index * 100 })
    }
    flowerPos.forEach(pos => {
      flowerProps.push({ x: pos.x * 100 + 25, y: pos.y * 100 + 25 })
    })
    
    return (
      <div id='board' className={classes.root} onClick={this.handleClick} onContextMenu={this.handleRightClick}>
        <svg id='board-svg' className={classes.board} viewBox='0 0 1500 1500'>
          <defs>
            <g id='flower'>
              <path className={classes.flower1} d='M33.55,14.42A20.37,20.37,0,0,1,40.4,11.3c2.71-.67,6-.57,7.73,1.63,1.87,2.4,1.8,7,.54,9.34-.71.47-4,2.48-4.71,2.93-3.27,2-3.58,1.51-7.31,2.44,3.68,2.42,4.88,7.24,5.11,11.64.1,1.79,0,3.76-1.16,5.11-1.52,1.74-4.23,1.73-6.51,1.28-3.76-.74-5.38-3.76-6.94-7.26.71,3.47-4.76,8.15-8.3,8.34S12,44.29,10.79,41a10.31,10.31,0,0,1,2.52-10.72,10.59,10.59,0,0,1-10.76-3.8,10.56,10.56,0,0,1-.7-11.38,4.48,4.48,0,0,1,1.83-1.9,5.62,5.62,0,0,1,2.19-.42c3.93-.23,8.3,1.3,12.23,1.07-.68-2.92-.22-7.93,1.87-10.08a9.17,9.17,0,0,1,8.24-2.56,4.28,4.28,0,0,1,2.11,1,5.12,5.12,0,0,1,1,1.59A23.81,23.81,0,0,1,33.55,14.42Z'/>
              <ellipse className={classes.flower2} cx='25' cy='25' rx='6.65' ry='6.66' transform='rotate(-41.16 24.995 25.004)'/>
            </g>
            <Cat type='blackcat'/>
            <Cat type='whitecat'/>
            <Fukidashi id='nya' word='にゃー！'/>
            <Fukidashi id='nyan' word='にゃん！'/>
            <Fukidashi id='nyanko' word='にゃんこ！'/>
            <ResultOfMove id='correct' word={<Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.CORRECT}/>} mark='check' color={'#8fc31f'}/>
            <ResultOfMove id='incorrect' word={<Word wid={WID.PLAY_PAGE.NYANKO_PUZZLE_PAGE.INCORRECT}/>} mark='cross' color={'#8fc31f'}/>
          </defs>
          <rect x='0' y='0' width='1500' height='1500' fill={theme.palette.primary.main}/>
          {lineProps.map((lineProp, index) => 
            <line className={classes.line} x1={lineProp.x1} y1={lineProp.y1} x2={lineProp.x2} y2={lineProp.y2} key={index}/>
          )}
          {flowerProps.map((prop, index) => 
            <use xlinkHref="#flower" x={prop.x} y={prop.y} className={classes.flower} style={{ transformOrigin: `${prop.x + 25}px ${prop.y + 25}px` }} key={index}/>
          )}
          {moves !== undefined && moves.map((move, index) =>
            <use
              xlinkHref={`#${move.color}`}
              x={move.x * 100}
              y={move.y * 100}
              className={classes.cat}
              style={{ transformOrigin: `${move.x * 100 + 50}px ${move.y * 100 + 50}px` }}
              key={catKey === undefined ? index : `${catKey}/${index}`}
            />
          )}
          {calls && calls.length > 0 && moves.length > 0 && calls.map((call, index) => {
            let move = moves[moves.length - 1]
            return (
              <AnimationFukidashi x={move.x} y={move.y} key={`${index} ${move.x} ${move.y}`} delay={index * 1100} call={call}/>
            )
          })}
          {result === 'correct' ? (
            <use xlinkHref='#correct' x={250} y={600} className={classes.resultOfMove} style={{ transformOrigin: '750px 750px' }}/>
          ) : result === 'incorrect' ? (
            <use xlinkHref='#incorrect' x={250} y={600} className={classes.resultOfMove} style={{ transformOrigin: '750px 750px' }}/>
          ) : undefined}
        </svg>
        {afterComponent}
      </div>
		)
  }
}

export default withStyles(styles, { withTheme: true })(Board)
