import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, ButtonBase } from '@material-ui/core'
import { GoodsCard } from '../Common'
import { Link } from 'react-router-dom'
import { makePath } from '../../lib';

const styles = theme => ({
})

class GoodsTopPage extends React.Component {
  componentDidMount() {
    this.props.mount()
  }
  
  render() {
    const { posts, language } = this.props

    return posts !== undefined ? (
      <Grid container spacing={16}>
        {posts.map(post => {
          return (
            <Grid item key={post.id} xs={6} sm={4} md={3}>
              <ButtonBase component={Link} to={makePath(`/goods/${post.slug}`, language)}>
                <GoodsCard post={post}/>
              </ButtonBase>
            </Grid>
          )
        })}
      </Grid>
    ) : (<div></div>)
  }
}

export default withStyles(styles, { withTheme: true })(GoodsTopPage)
